import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import CheckoutForm from "../stripe/checkout-form/checkout-form";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CircularProgress } from "@mui/material";

interface AddPaymentMethodProps {}

const AddPaymentMethod = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [options, setOptions] = useState({
    clientSecret: "",
    paymentMethodCreation: "manual",
  });
  const [loading, setLoading] = useState<boolean>(true);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    const fetchClientSecret = async () => {

      try {
        const response = await axios.post(
          "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/createSetupIntent",
          {
            authorization: localStorage.getItem("videoPortal"),
          }
        );

        setClientSecret(response.data.clientSecret);
        setOptions({ ...options, clientSecret: response.data.clientSecret });
        
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
      finally{
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, []);
  return (
    <> {loading ? <div className="overlay"><CircularProgress /></div> : <>
    {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>}
      
    </>
  );
};

export default AddPaymentMethod;
