import { CircularProgress } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

interface CooldownTimerProps {
  nftID?: number;
  time: any;
  cooldownPeriod: number;
  additionalChild?: ReactNode;
  onCooldownEnd?: (isCooldownEnd: boolean) => void;
}
const CooldownTimer: React.FC<CooldownTimerProps> = ({
  nftID,
  time,
  cooldownPeriod,
  additionalChild,
  onCooldownEnd,
}) => {
  cooldownPeriod = cooldownPeriod * 60 * 1000;
  // cooldownPeriod = cooldownPeriod * 60 * 60 * 1000;

  const [remainingTime, setRemainingTime] = useState<number>(cooldownPeriod);

  useEffect(() => {
    const updateRemainingTime = () => {
      const currentDateTimestamp = Date.now();
      const diffMilliseconds = currentDateTimestamp - time!;
      const timeLeft = cooldownPeriod - diffMilliseconds;

      if (timeLeft <= 0) {
        setRemainingTime(0);
        if (onCooldownEnd) {
          onCooldownEnd(true);
        }
      } else {
        setRemainingTime(timeLeft);
      }
    };

    const interval = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(interval);
  }, [time, cooldownPeriod]);

  const formatTime = (milliseconds: number) => {
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

    return `${minutes}: ${seconds}`;
  };

  return (
    <div>
      {remainingTime === cooldownPeriod ? (
        <CircularProgress />
      ) : (
        <>
          {additionalChild}{" "}
          {remainingTime > 0 && <div>{formatTime(remainingTime)} </div>}
        </>
      )}
    </div>
  );
};

export default CooldownTimer;
