import React, { useEffect, useRef, useState } from "react";
import styles from "./session-preview.module.scss";
import WButton from "../../components/button/button";
import { WIconButton } from "../../components/icon-button/icon-button";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WSessionPopup from "../../prefabs/session-popup/session-popup";
import { useNavigate } from "react-router-dom";
import subscriptionService from "../../services/subscription.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import utilityService from "../../utils/utility.service";
import { Video_Metadata } from "../../services/spica/bucket/bucket";
import { setSessionVideo } from "../../redux/session-video/session-video";
// import videoSource from "../../assets/kalabalık.mp4";

function SessionPreview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [haveSubscription, setHaveSubscription] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [engouhSessionCount, setEngouhSessionCount] = useState<boolean>(false)


  const [videoInformation, setVideoInformation] = useState<Video_Metadata>({});

  const user = useSelector((state: RootState) => state.user.user);
  const videoRef = useRef<HTMLVideoElement>(null);
  const video = useSelector(
    (state: RootState) => state.sessionVideo.sessionVideo
  );
  const getVideoInformation = () => {
    if (!video._id) {
      const video = JSON.parse(localStorage.getItem("video") || "{}");
      dispatch(setSessionVideo(video));
      setVideoInformation(video);
      return;
    }

    setVideoInformation(video);
  };

  const handleSession = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    navigate("/");
  };

  const checkSubscription = async () => {
    try {
      const subscription =
        await subscriptionService.getSubscriptionInformation(user?.subscriptions?._id!);
        const sessionCount = user?.remaining_sessions! + user?.one_time_session_count!;
        console.log("sessionCount", sessionCount);
        
        setEngouhSessionCount(sessionCount>0)
      setHaveSubscription(subscription?.is_active!);
    } catch (error) {
      console.error(error);
      setHaveSubscription(false);
    }
  };

  useEffect(() => {
    getVideoInformation();
    checkSubscription();

  }, []);

  useEffect(() => {
    if (videoInformation) {
      playVideo();
    }
  }, [videoInformation]);
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (

    <div className={styles["container"]}>
      <div className={styles["demo"]}>
     {videoInformation?.video_demo  && <video ref={videoRef} preload="auto" loop muted={isMuted} controls autoPlay>
        <source src={videoInformation?.video_demo} type="video/mp4"/>
      </video>}

      </div>
     
      <div className={styles["wrap"]}>
        <div className={styles["main"]}>
          <div className={styles["cancel-button"]}>
            <WIconButton
              className={styles["icon-button"]}
              onClick={handleCancel}
            >
              <HighlightOffIcon style={{ width: "100%", height: "100%" }} />
            </WIconButton>
          </div>
          <div className={styles["content"]}>
            <div className={styles["content-top"]}>
              <p className={styles["title"]}>{videoInformation?.title}</p>
              <p className={styles["sub-title"]}>
                {videoInformation?.subtitle}
              </p>
              <div className={styles["content-middle"]}>
                <div className={styles["button-box"]}>
                  <WButton
                    onClick={handleSession}
                    children={"Seans Başlat"}
                    type="outlined"
                    borderColor="white"
                    bgHover="transparent"
                    color="white"
                    borderRadius="5px"
                  ></WButton>
                  <div className={styles["duration"]}>
                    <div className={styles["duration-line-box"]}>
                      <div className={styles["duration-line"]}></div>
                    </div>
                    <div className={styles["duration-text"]}>
                      {utilityService.formatDate(
                        videoInformation?.duration_seconds || 0
                      )}
                    </div>
                  </div>
                </div>
                <WIconButton className={styles["icon-button"]} onClick={()=> setIsMuted(!isMuted)}>

                  {isMuted ?  <VolumeOffIcon style={{ width: "100%", height: "100%" }} /> :  <VolumeUpIcon style={{ width: "100%", height: "100%" }} />}
                 
                </WIconButton>
              </div>
            </div>

            <p className={styles["content-bottom"]}>
              {videoInformation?.description}
            </p>
          </div>
        </div>
      </div>
      <WSessionPopup
        open={open}
        setOpen={setOpen}
        haveSubscription={haveSubscription}
        enoughSessionCount={engouhSessionCount}
      />
    </div>
  );
}

export default SessionPreview;
