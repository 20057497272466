import React, { useEffect, useState } from 'react'
import WAccountCard from '../../account-card/account-card'
import styles from "./supervision.module.scss"
import { CircularProgress, Switch } from '@mui/material';
import { globalColors } from '../../../style/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProduct } from '../../../redux/payment/payment.slice';
import WButton from '../../button/button';
import databaseService from '../../../services/database.service';

const Supervision = () => {
  const [superVision, setSuperVision] = useState<any>({});
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  let selectedProduct = superVision[selectedProductIndex];

  const superVisionPayment = async (product: any) => {
    localStorage.setItem("selectedPlan", JSON.stringify(product) || "");
    dispatch(setProduct(product));

    navigate("/payment/supervision");
  }

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProductIndex(event.target.checked ? 1 : 0);
  };

  const getSupervisionProducts = async () => {
    try {
      const supervisionProducts = await databaseService.getPlans({"product.product_type": "supervision"});
      setSuperVision(supervisionProducts);
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }
  
  useEffect(() => {
    getSupervisionProducts();
  }, [])
  
  

  return (
    loading ? <div className='overlay'>
      <CircularProgress />
    </div> : (
     <>
      <WAccountCard>
      <div className={styles["additional-products"]}>
        <h3>{selectedProduct?.product?.title}</h3>
        <span>Saatlik</span>
        <Switch
          checked={selectedProductIndex === 1}
          onChange={handleToggleChange}
          inputProps={{ "aria-label": "Toggle between hourly and monthly" }}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: globalColors.primary,
            },
            "& .MuiSwitch-track": {
              backgroundColor: `${globalColors.primary} !important`,
            },
          }}
        />
        <span>Aylık</span>
  
        <p>Price: {selectedProduct?.price} TL</p>
        <WButton borderRadius="5px" onClick={()=>{superVisionPayment(selectedProduct)}}>Ek Paket Satın Al</WButton>
      </div>
    </WAccountCard>
     </>
    )
  )
}

export default Supervision;