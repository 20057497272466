import { PUBLIC_APIKEY, PUBLIC_URL } from "../utils/constants";
import authService from "./auth.service";
import { initialize, invoice, invoices_and_charges, legal_terms, pricing_plans, stripe_pricing_plans } from "./spica/bucket/bucket";

class DatabaseService {
  constructor() {
    initialize({ identity: authService.getToken(), publicUrl: PUBLIC_URL });
  }

  getInvoices() {
    return invoices_and_charges
      .resolveRelations(["payment_method"]).sort({"created_at": -1}).getAll();
    }

  getPlans(filter: any = {}) {
    return stripe_pricing_plans.resolveRelations(["product"]).filter(filter).getAll();
  }
  
  getLegalTerms(term_tag: string){
    initialize({ apikey: PUBLIC_APIKEY, publicUrl: PUBLIC_URL });
    return legal_terms.filter({tag: term_tag}).getAll();
  }
}

const databaseService = new DatabaseService();
export default databaseService;
