import * as Identity from "@spica-devkit/identity";
import { Patients, initialize, patients, users } from "./spica/bucket/bucket";
import { PUBLIC_URL } from "../utils/constants";

class PatientService {
  constructor() {
    initialize({
      identity: localStorage.getItem("videoPortal") ?? "",
      publicUrl: PUBLIC_URL,
    });
  }
  insert(patient: Patients) {
    return patients.insert(patient);
  }

  getPatients(userId: string) {
    return patients.getAll({
      queryParams: {
        filter: {
          doctor: userId,
        },
      },
    });
  }

  updatePatient(patientId: string, patchFields: {}) {
    return patients.patch({ _id: patientId, ...patchFields });
  }
}

const patientService = new PatientService();
export default patientService;
