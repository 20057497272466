import React, { useState } from "react";
import styles from "./change-password.module.scss";
import WButton from "../../components/button/button";
import WInput from "../../components/input/input";
import { useFormik } from "formik";
import { globalColors } from "../../style/colors";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import authService from "../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, Modal } from "@mui/material";
import WModalCard from "../../components/modal-card/modal-card";
import { setUser } from "../../redux/user/user.slice";
import usePasswordVisibility from "../../hooks/usePasswordVisibility";

interface iChangePasswordProps {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function ChangePassword() {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { visibility, showPassword } = usePasswordVisibility();

  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      changePassword(values);
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required("Lütfen mevcut parolanızı girin"),
      newPassword: Yup.string()
        .min(8, "Şifre çok kısa - en az 8 karakter olmalıdır.")
        .max(60, "Şifre çok uzun - en fazla 60 karakter olmalıdır.")
        .required("Lütfen yeni parolanızı girin"),
      confirmPassword: Yup.string()
        .required("Lütfen yeni parolanızı tekrar girin")
        .oneOf([Yup.ref("newPassword")], "Parolalar eşleşmiyor"),
    }),
  });

  const changePassword = async (values: iChangePasswordProps) => {
    setLoading(true);
    try {
      const loginRes = await authService.login(
        user.email!,
        values.currentPassword
      );
      if (loginRes) {
        setOpen(true);

        await authService.changePasswordWhileLoggedIn(
          user?._id!,
          values.newPassword
        );

        setLoading(false);
        authService.logout();
        setTimeout(() => {
          dispatch(setUser({}));
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Mevcut parolanızı yanlış girdiniz.");
    }
  };
  return (
    <>
      <div className={styles["change-password"]}>
        <WModalCard className={styles["change-password-card"]}>
        <div className={styles["content"]}>
          <h1>Parolayı Değiştir</h1>
          {errorMessage.length > 0 && (
            <span className="error">{errorMessage}</span>
          )}
          <form>
            <div className={styles["input-container"]}>
              <WInput
                type={visibility.currentPassword ? "text" : "password"}
                value={formik.values.currentPassword}
                onChange={formik.handleChange("currentPassword")}
                placeholder="Mevcut Parola"
                id="currentPassword"
              ></WInput>
              <div className="show-password">
                {visibility.currentPassword ? (
                  <VisibilityIcon
                    onClick={() => showPassword("currentPassword")}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => showPassword("currentPassword")}
                  />
                )}
              </div>
              {formik.touched.currentPassword &&
              formik.errors.currentPassword ? (
                <span className="error">{formik.errors.currentPassword}</span>
              ) : null}
            </div>
            <div className={styles["input-container"]}>
              <WInput
                type={visibility.newPassword ? "text" : "password"}
                value={formik.values.newPassword}
                onChange={formik.handleChange("newPassword")}
                placeholder="Yeni Parola (8 - 60 karakter)"
                id="newPassword"
              ></WInput>
              <div className="show-password">
                {visibility.newPassword ? (
                  <VisibilityIcon onClick={() => showPassword("newPassword")} />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => showPassword("newPassword")}
                  />
                )}
              </div>
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <span className="error">{formik.errors.newPassword}</span>
              ) : null}
            </div>
            <div className={styles["input-container"]}>
              <WInput
                type={visibility.confirmPassword ? "text" : "password"}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange("confirmPassword")}
                placeholder="Yeni Parolanızı Tekrar Girin"
                id="confirmPassword"
              ></WInput>
              <div className="show-password">
                {visibility.confirmPassword ? (
                  <VisibilityIcon
                    onClick={() => showPassword("confirmPassword")}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => showPassword("confirmPassword")}
                  />
                )}
              </div>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <span className="error">{formik.errors.confirmPassword}</span>
              ) : null}
            </div>

           <div  className="w-full flex gap-2">
           <WButton borderRadius="10px" onClick={formik.handleSubmit} className="basis-1/2">
              {loading ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                <span>Devam Et</span>
              )}
            </WButton>
            <WButton
              type="outlined"
              borderRadius="10px"
              borderColor={globalColors.lightFontColor}
              color={globalColors.lightFontColor}
              onClick={() => navigate("/profile")}
              className="basis-1/2"
            >
              İptal
            </WButton>
           </div>
          </form>
        </div>
        </WModalCard>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <WModalCard width="30%" borderRadius="10px">
          <div className={styles["modal-content"]}>
            <h3>Parolanız başarıyla değiştirildi</h3>
            <span>Login sayfasına yönlendiriliyorsunuz...</span>
            <CircularProgress color="inherit"/>
          </div>
        </WModalCard>
      </Modal>
    </>
  );
}

export default ChangePassword;
