import styles from "./subscription.module.scss";

import SubscriptionPlans from "../../../components/subscription/subscription-plans/subscription-plans";
import Supervision from "../../../components/subscription/supervision/supervision";
import Seans from "../../../components/subscription/seans/seans";
import WAccountCard from "../../../components/account-card/account-card";

function Subscription() {
  return (
    <div className={styles["subcription"]}>
      <SubscriptionPlans />
      <Supervision />
      <Seans />
    </div>
  );
}

export default Subscription;
