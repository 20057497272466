import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface iPaymentState {
  product: any; // TODO: define type
  paymentIntet: any; // TODO: define type
  options: any; // TODO: define type
}

const initialState: iPaymentState = {
  product: null,
  paymentIntet: null,
  options: {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    clientSecret: "",
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#f94c10",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
      },
      rules: {},
    },
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setProduct(state, action: PayloadAction<any>) {
      state.product = action.payload;
    },
    setPaymentIntent(state, action: PayloadAction<any>) {
      state.paymentIntet = action.payload;
    },
    setOption(state, action: PayloadAction<Partial<iPaymentState["options"]>>) {
      state.options = { ...state.options, ...action.payload };
    },
  },
});

export const { setProduct, setPaymentIntent, setOption } = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
