import * as TWEEN from 'tween.js';

class ITweenService {
    public animateVector3(vector: any, targetVector: any, duration: number) {
        const tween = new TWEEN.Tween(vector)
            .to(targetVector, duration)
            .easing(TWEEN.Easing.Quadratic.InOut);

        tween.start();

        function animate() {
            requestAnimationFrame(animate);
            TWEEN.update();
        }
        animate();
    }
}

const TweenService = new ITweenService();
export default TweenService;