import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import styles from "./checkout-form.module.scss";
import PaymentMethods from "../../../components/payment-methods/payment-methods";
import {
  WFormControl,
  WModal,
  WSelect,
} from "../../../components/wrapper/wrapper";
import { WMenuItem } from "../../../components/menu-item/menu-item";
import { Payment_Methods } from "../../../services/interfaces";
import { payment_methods } from "../../../services/spica/bucket/bucket";
import { CircularProgress, SelectChangeEvent } from "@mui/material";
import { globalColors } from "../../../style/colors";
import WModalCard from "../../../components/modal-card/modal-card";
import WButton from "../../../components/button/button";
import done from "../../../assets/img/check.png";
import error from "../../../assets/img/error.png";
import { useNavigate } from "react-router-dom";

interface CheckoutFormProps {
  action?: string;
}

const CheckoutForm = ({ action = "subscribe" }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const cardRef = useRef();
  const user = useSelector((state: RootState) => state.user.user);
  const [isNewCardSetted, setIsNewCardSetted] = useState<boolean>(false);

  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState<Payment_Methods | null>(
    null
  );

  const plan = JSON.parse(localStorage.getItem("selectedPlan") || "");

  const [subscriptionOperationInform, setSubscriptionOperationInform] =
    useState<any>({
      isModalActive: false,
      content: {
        title: "",
        description: "",
        img: <img src={error} alt="error" />,
        button: (
          <WButton
            onClick={() =>
              setSubscriptionOperationInform({
                ...subscriptionOperationInform,
                isModalActive: false,
              })
            }
          >
            Kapat
          </WButton>
        ),
      },
    });

  const payment = useSelector((state: RootState) => state.payment.paymentIntet);

  const handleSubmit = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    let payment_method_id = "";

    if (paymentMethods.length === 0 || isNewCardSetted) {
      elements.submit();

      const card = elements.getElement(PaymentElement);

      const {
        error: createPaymentMethodError,
        paymentMethod: createaPaymentMethod,
      } = await stripe.createPaymentMethod({
        element: card!,
      });

      payment_method_id = createaPaymentMethod?.id!;
    } else {
      payment_method_id = paymentMethod?.id!;
    }

    switch (plan?.product?.product_type) {
      case "supervision":
        handleOneTimePayment(payment.paymentIntent, payment_method_id, plan);
        break;
      case "one_seans":
        handleOneTimePayment(payment.paymentIntent, payment_method_id, plan);
        break;
      default:
        if (!isCardDefault(payment_method_id!)) {
          await attachPaymentMethodForSubscription(payment_method_id!);
        }

        if (action === "update-subscription" && user.subscriptions?._id) {
          await confirmSubscriptionUpdate();
          return;
        }
        if (action === "retry-subscription" && user.subscriptions?._id) {
          await retrySubscription();
          return;
        }
        await createSubscription(payment_method_id);
        break;
    }
    sessionStorage.removeItem("paymentIntentSession");
  };

  const isCardDefault = (paymentMethodId: string) => {
    const defaultPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === paymentMethodId
    );
    return !!defaultPaymentMethod;
  };

  const attachPaymentMethodForSubscription = async (
    paymentMethodId: string
  ) => {
    try {
      await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/attachPaymentMethodForSubscription",
        {
          paymentMethodId: paymentMethodId!,
          authorization: localStorage.getItem("videoPortal"),
        }
      );
    } catch (error) {
      console.error("Error", error);
    }
  };

  const retrySubscription = async () => {
    try {
      const { data } = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/retrySubscriptionPayment",
        {
          authorization: localStorage.getItem("videoPortal"),
          paymentMethodId: paymentMethod?.id,
        }
      );
      await confirm3DSecure(data);
      createSubsctioptionInformationModal(true, "Something went wrong");
    } catch (error) {
      console.error("Error", error);
      createSubsctioptionInformationModal(false, "Something went wrong");
    }
  };

  const createSubscription = async (paymentMethodId: string) => {
    try {
      const { data } = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/stripe/subscription/create",
        {
          price: plan,
          stripe_customer_id: user?.stripe_customer_id,
          authorization: localStorage.getItem("videoPortal"),
          subscription_id: user?.subscriptions?._id,
          payment_method_id: paymentMethodId,
        }
      );
      await confirm3DSecure(data);
    } catch (error: any) {
      console.error("Subscription creation failed:", error);
      createSubsctioptionInformationModal(false, "Something went wrong");
    } finally {
      setLoading(false);
      setConfirmationModal(false);
    }
  };

  const handleOneTimePayment = async (
    paymentIntent: any,
    paymentMethodId: string,
    plan?: any
  ) => {
    try {
      const { data } = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/handleOneTimePayment",
        {
          authorization: localStorage.getItem("videoPortal"),
          paymentIntent: paymentIntent,
          paymentMethodId: paymentMethodId,
          plan: plan,
        }
      );
      await confirm3DSecure(data);
    } catch (error) {
      console.error("Error", error);
      createSubsctioptionInformationModal(false, "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const confirmSubscriptionUpdate = async () => {
    try {
      const response = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/updateSubscription",
        {
          authorization: localStorage.getItem("videoPortal"),
          newPlan: plan,
          oldPlanId: user?.subscriptions?.price?.id,
        }
      );
      createSubsctioptionInformationModal(
        true,
        `aboneliğiniz ${plan?.product?.title} planına güncellendi. Yeni aboneliginiz ${user.subscriptions?.next_payment} tarihinden itibaren geçerli olacaktır.`
      );
    } catch (error: any) {
      console.error(error);
      createSubsctioptionInformationModal(false, error.message as string);
    } finally {
    }
  };

  const [paymentMethods, setPaymentMethods] = useState<Payment_Methods[]>([]);

  const getPaymentMethods = async (filter: any = {}) => {
    const paymentMethods = await payment_methods.filter(filter).getAll();
    setPaymentMethods(paymentMethods);
  };

  const confirm3DSecure = async (paymentIntent: any) => {
    if (!stripe || !elements) {
      return;
    }

    if (paymentIntent.status === "requires_action") {
      try {
        const {
          error: confirmationError,
          paymentIntent: confirmedPaymentIntent,
        } = await stripe.confirmCardPayment(paymentIntent.client_secret);

        if (confirmationError) {
          console.error("3D Secure authentication failed:", confirmationError);
          createSubsctioptionInformationModal(
            false,
            "İşleminiz gerçekleştirilemedi."
          );
          return;
        }

        if (confirmedPaymentIntent.status === "succeeded") {
          createSubsctioptionInformationModal(
            true,
            "İşleminiz hesabınıza başarıyla yansıtıldı."
          );
        }
      } catch (error) {
        console.error(
          "An unexpected error occurred during payment confirmation:",
          error
        );
        createSubsctioptionInformationModal(
          false,
          "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyiniz."
        );
      }
    } else if (paymentIntent.status === "succeeded") {
      console.log("Payment succeeded!");
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.is_default
      );
      setPaymentMethod(defaultPaymentMethod! || null);
    }
  }, [paymentMethods]);

  const createSubsctioptionInformationModal = (
    isSucceded: boolean,
    description: string = ""
  ) => {
    if (isSucceded) {
      description = description || "";
      setSubscriptionOperationInform({
        isModalActive: true,
        content: {
          title: "Tebrikler!",
          description: `Ödeme işlemi başarıyla tamamlandı. ${description}. Keyifli kullanımlar dileriz!`,
          img: <img src={done} alt="done" />,
          button: (
            <WButton
              onClick={() => {
                setConfirmationModal(false);
                navigate("/");
              }}
            >
              Anasayfaya Git
            </WButton>
          ),
        },
      });
    } else {
      setSubscriptionOperationInform({
        isModalActive: true,
        content: {
          title: "Hata!",
          description:
            "Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyiniz.",
          img: <img src={error} alt="error" />,
          button: (
            <WButton
              onClick={() => {
                setSubscriptionOperationInform({
                  ...subscriptionOperationInform,
                  isModalActive: false,
                });
                setConfirmationModal(false);
              }}
            >
              Kapat
            </WButton>
          ),
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1>Kart Bilgileri</h1>
        {paymentMethods.length > 0 && (
          <span
            className="underline cursor-pointer"
            onClick={() => setIsNewCardSetted(!isNewCardSetted)}
          >
            {!isNewCardSetted ? "Başka Kartla Öde" : "Kayıtlı Kartımla Öde"}
          </span>
        )}
      </div>
      {isNewCardSetted || paymentMethods.length === 0 ? (
        <>
          <form
            // onSubmit={(event) => handleSubmit(event, "new_card")}
            className={styles["payment-form"]}
          >
            <PaymentElement />
          </form>
          <button
            className={styles["submit"]}
            onClick={() => setConfirmationModal(true)}
          >
            Devam Et
          </button>
        </>
      ) : (
        <>
          {paymentMethods?.length > 0 ? (
            <div className={`${styles["payment-methods"]} flex flex-col gap-2`}>
              <WFormControl>
                <WSelect
                  sx={{
                    "& .MuiSelect-icon": {
                      color: globalColors.primary,
                    },
                    "& .Mui-focused": {
                      borderColor: globalColors.primary,
                    },
                  }}
                  name="paymentMethod"
                  value={paymentMethod ? paymentMethod?._id : ""}
                  onChange={(event) => {
                    const selectedPaymentMethod = paymentMethods.find(
                      (pm) => pm._id === event.target.value
                    );
                    setPaymentMethod(selectedPaymentMethod!);
                  }}
                >
                  {paymentMethods?.map((paymentMethod, index) => (
                    <WMenuItem
                      value={paymentMethod?._id}
                      key={paymentMethod?._id}
                    >
                      <div className="flex justify-between w-full">
                        <span className={styles["title"]}>
                          {paymentMethod?.title}
                        </span>
                        <span className={styles["last4"]}>
                          **** **** ****{paymentMethod?.last4}
                        </span>
                      </div>
                    </WMenuItem>
                  ))}
                </WSelect>

                <button
                  className={styles["submit"]}
                  onClick={() => setConfirmationModal(true)}
                >
                  Devam Et
                </button>
              </WFormControl>
            </div>
          ) : (
            <div>Ödeme yöntemi bulunamadı</div>
          )}
        </>
      )}

      {confirmationModal && (
        <WModal
          open={confirmationModal}
          onClose={() => setConfirmationModal(false)}
        >
          <WModalCard width="40%" borderRadius="10px" type="white">
            <div className="flex flex-col gap-3">
              {plan?.product?.product_type === "subscription" ? (
                <div className="flex flex-col gap-3">
                  {user.subscriptions?._id ? (
                    <h2>Abonelik Değişimi</h2>
                  ) : (
                    <h2>Abonelik Başlatma</h2>
                  )}
                  {!isCardDefault(paymentMethod?.id!) && (
                    <p>
                      Abonelik işlemlerinde seçtiğiniz kartınız varsayılan
                      kartınız olarak ayarlanacaktır.
                    </p>
                  )}
                  {isNewCardSetted && (
                    <div>
                      Abonelik işlemlerinde eklemek üzere olduğunuz kartınız
                      varsayılan kartınız olarak ayarlanacaktır.
                    </div>
                  )}
                  <p>
                    Abonelik planınız {plan?.price} TRY değerindeki{" "}
                    <span>{plan?.product?.title}</span> planı olarak
                    güncellenecektir.
                  </p>
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <h2>Ödeme Onayı</h2>
                  <p>
                    {plan?.product?.title} ürünü için {plan?.price} TRY
                    değerindeki ödeme işlemini onaylıyor musunuz?
                  </p>
                </div>
              )}
              <div className="flex gap-3 w-full">
                <WButton
                  type="secondary"
                  className={styles["modal-button"]}
                  onClick={() => setConfirmationModal(false)}
                  bgHover="#00000042"
                  borderRadius="5px"
                >
                  {" "}
                  Iptal{" "}
                </WButton>
                <WButton
                  className={styles["modal-button"]}
                  borderRadius="5px"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Onayla"
                  )}
                </WButton>
              </div>
            </div>
          </WModalCard>
        </WModal>
      )}

      <WModal open={subscriptionOperationInform?.isModalActive}>
        <WModalCard width="40%" borderRadius="10px" type="white">
          <div className={`${styles["done"]} flex flex-col gap-3 items-center`}>
            <h2>{subscriptionOperationInform.title}</h2>
            {subscriptionOperationInform?.content?.img}
            <p className="text-center">
              {subscriptionOperationInform?.content?.description}
            </p>
            {subscriptionOperationInform?.content?.button}
          </div>
        </WModalCard>
      </WModal>
    </div>
  );
};

export default CheckoutForm;
