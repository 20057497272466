import React, { useEffect, useState } from "react";
import styles from "./onboarding.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import userSerivce from "../../services/user.service";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/user/user.slice";
import { WCircularProgress } from "../../components/progress/circular/circular-progress";
import databaseService from "../../services/database.service";
import {
  setNotificationMetaData,
  setNotifications,
} from "../../redux/notification/notification.slice";
import notificationService from "../../services/notification.service";
import WNavBar from "../../prefabs/nav-bar/nav-bar";
import WFooter from "../../prefabs/footer/footer";
import { iUser } from "../../interfaces/common";
import { error } from "console";


function Onboarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const path = location.pathname.split("/");
    const isCreatePasswordPath = path[1] === "create-password";
    const isRemoveAccountPath = path[1] === "completed-remove-account";

    if (!isCreatePasswordPath && !isRemoveAccountPath) {
     try {
      checkIsLoggedin();
     } catch (error) {
      console.error(error)
      setLoading(false);
     }
    } else {
      setLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("videoPortal");
  //   if (token) {
  //     handleLogout();
  //   }
  // }, []);

  const checkIsLoggedin = async () => {
    try {
      const res: any = await authService.isLoggedIn();
      initializeData(res?.attributes?.user);
    } catch (err) {
      setLoading(false);
    }
  };

  const initializeData = (userId: string) => {
    Promise.all([getUser(userId)])
      .then((res) => {
        const user = res[0];
        if (user?.notifications) {
          getUnseenNotifications(user?._id);
        }
        setLoading(false);
      })
      .catch(() => {});
    //TODO: add error handling
  };

  const getUser = (userId: string) => {
    return userSerivce.getUser(userId).then((res) => {
      dispatch(setUser(res));
      return res;
    });
  };
  const getUnseenNotifications = async (userId: string) => {
    try {
      const notificationsMetaData =
        await notificationService.getNotificationsMetaData(userId);

      dispatch(setNotificationMetaData(notificationsMetaData[0]!));

      dispatch(
        setNotifications([
          ...(notificationsMetaData[0]?.general_unreaded_notifications || []),
          ...(notificationsMetaData[0]?.user_specific_unreaded_notifications ||
            []),
        ])
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fullScreen = ["/session-preview", "/session/:sessionId"].some(
    (path) =>
      location.pathname === path || location.pathname.startsWith("/session/")
  );

  // const handleLogout = () => {
  //   const currentDevice = navigator.userAgent;

  //   const realtimeLoginSession = authService.realtimeLoginSession();
  //   realtimeLoginSession.subscribe((res) => {
  //     if (res.length > 0) {
  //       const latestSession = res[0];
  //       if (latestSession.device !== currentDevice) {
  //         console.log("here");
          
  //         console.log("New login detected from a different device. Logging out current session.");
  //         dispatch(setUser({} as iUser));
  //         authService.logout();
  //         navigate("/login");
  //       } else {
  //         console.log("Login detected from the same device. Maintaining current session.");
  //       }
  //     } else {
  //       console.log("No active sessions found.");
  //     }
  //   });
  // };

  return (
    <>
      {loading ? (
        <div className={styles["overlay"]}>
          <WCircularProgress />
        </div>
      ) : (
        <>
          {!fullScreen && <WNavBar />}
          <Outlet />
          {!fullScreen && <WFooter />}
        </>
      )}
    </>
  );
}

export default Onboarding;
