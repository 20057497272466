import { initialize, users } from "./spica/bucket/bucket";
import authService from "./auth.service";
import { iUser } from "../interfaces/common";

class UserSerivce {

  initialize() {
    const token = authService.getToken();
    if (!token) return;    
    initialize({ identity: token });
  }

  getUser(id: string) {
    this.initialize();
    return users.resolveRelations(["subscriptions.price"]).get(id);
  }

  updateUser(id: string, data: any) {
    this.initialize();
    return users.patch({ _id: id, ...data })
  }

}

const userSerivce = new UserSerivce();
export default userSerivce;
