import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "./checkout-form/checkout-form";
import styles from "./create-subscription.module.scss";
import { CircularProgress } from "@mui/material";
import { globalColors } from "../../style/colors";
import WButton from "../../components/button/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPaymentIntent } from "../../redux/payment/payment.slice";

const CreateSubscription = () => {
  const { action } = useParams();
  const navigate = useNavigate();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any>({
    // layout: {
    //   type: "tabs",
    //   defaultCollapsed: false,
    // },
    clientSecret: "",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#f94c10",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
      },

      rules: {},
    },
  });
  const [clientSecret, setClientSecret] = useState<string>("");
  const plan = JSON.parse(localStorage.getItem("selectedPlan") || "");

  const [isLoading, setIsloading] = useState<boolean>(true);

  const checkPaymentIntent = async (): Promise<string | null> => {
    const storedPaymentIntentId = JSON.parse(
      sessionStorage.getItem("paymentIntentSession") || "null"
    );

    if (storedPaymentIntentId) {
      try {
        const response = await axios.post(
          "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/retrievePaymentIntent",
          {
            paymentIntent_id: storedPaymentIntentId?.paymentIntent?.id,
            authorization: localStorage.getItem("videoPortal"),
          }
        );
        const paymentIntent = response.data;
        if (paymentIntent.metadata.productId === plan.product.id) {
          if (
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "canceled"
          ) {
            sessionStorage.removeItem("paymentIntentId");
            return null;
          }
          return storedPaymentIntentId;
        }

        sessionStorage.removeItem("paymentIntentId");
        return null;
      } catch (error) {
        console.error("Error fetching PaymentIntent:", error);
        sessionStorage.removeItem("paymentIntentId");
        return null;
      }
    }

    return null;
  };

  const createPaymentIntent = async () => {
    const session = await axios.post(
      "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/createPaymentIntent",
      {
        authorization: localStorage.getItem("videoPortal"),
        price: plan?.price,
        currency: plan?.currency,
        plan: plan,
      }
    );
    const paymentIntentSession = session.data;

    sessionStorage.setItem(
      "paymentIntentSession",
      JSON.stringify(paymentIntentSession)
    );

    return paymentIntentSession;
  };

  const initializePaymentIntent = async () => {
    const existingPaymentIntentId = await checkPaymentIntent();
    if (existingPaymentIntentId) {
      return existingPaymentIntentId;
    } else {
      const newPaymentIntent = await createPaymentIntent();
      return newPaymentIntent;
    }
  };

  useEffect(() => {
    const handlePayment = async () => {
      const payment = await initializePaymentIntent();

      dispatch(setPaymentIntent(payment));

      setOptions({
        ...options,
        clientSecret: payment.paymentIntent?.client_secret,
        customerSessionClientSecret: payment?.customerSession?.client_secret,
      });
      setClientSecret(payment?.paymentIntent?.client_secret);
      setIsloading(false);
    };

    if (!clientSecret) {
      if (action === "update-subscription" || action === "retry-subscription") {
        createSetupIntent();
        return;
      }
      handlePayment();
    }
  }, [clientSecret]);

  const createSetupIntent = async () => {
    try {
      const response = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/createSetupIntent",
        {
          authorization: localStorage.getItem("videoPortal"),
        }
      );
      setClientSecret(response.data.clientSecret);
      setOptions({ ...options, clientSecret: response.data.clientSecret });
    } catch (error) {
      console.error("Error fetching client secret:", error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className={styles["payment-container"]}>
      {isLoading ? (
        <div className={styles["loading"]}>
          <CircularProgress sx={{ color: globalColors.primary }} />
        </div>
      ) : (
        <div className={styles["payment-content"]}>
          <h1 className={styles["page-title"]}>Satın Alma İşlemine Devam Et</h1>
          <div className={styles["payment"]}>
            <div className={styles["stripe-payment-form"]}>
              {options?.clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm action={action} />
                </Elements>
              )}
            </div>
            <div className={styles["product-details"]}>
              <div className={styles["title"]}>
                <h2 className={styles["name"]}>{plan?.product?.title}</h2>
                <span className={styles["price"]}>
                  {plan?.price} {plan?.currency}
                </span>
              </div>
              <div className={styles["description"]}>
                {!!plan.count && `${plan.count}`} {plan?.product?.description}
              </div>
              <ul className={styles["details"]}>
                {plan?.product?.informations?.map(
                  (info: string, index: number) => (
                    <li key={index}>{info}</li>
                  )
                )}
              </ul>

              {action !== "retry-subscription" && (
                <div className={styles["change-conatiner"]}>
                  <WButton
                    onClick={() => {
                      navigate("/subscription");
                    }}
                    className={styles["change"]}
                  >
                    Ürünü değiştir{" "}
                  </WButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSubscription;
