import React, { useEffect, useState } from "react";
import styles from "./session-history.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  WAccordion,
  WAccordionDetails,
  WAccordionSummary,
} from "../../../components/accordion";
import WAccountCard from "../../../components/account-card/account-card";
import WButton from "../../../components/button/button";
import databaseService from "../../../services/database.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Patients, Sessions } from "../../../services/spica/bucket/bucket";
import utilityService from "../../../utils/utility.service";
import { CircularProgress, Modal } from "@mui/material";
import WModalCard from "../../../components/modal-card/modal-card";
import sessionService from "../../../services/session.service";
import { globalColors } from "../../../style/colors";
import { useFormik } from "formik";
import patientService from "../../../services/patient.service";
import EditableInfoRow from "../../../components/editableInfoRow/editable-info-row";

type SessionType = Sessions<["patient", "videos"], true>;

function SessionHistory() {
  const [expanded, setExpanded] = useState<string | false>();
  const [groupedSessions, setGroupedSessions] = useState<
    Record<string, SessionType[]>
  >({});

  const [selectedSession, setSelectedSession] = useState<
  SessionType
  >({});

  const [notes, setNotes] = useState<string>("");

  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editPatient, setEditPatient] = useState<string | null>(null);

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = async () => {
    try {
      const sessions = await sessionService.getSessionsInformations(user?._id!);

      if (!sessions[0]._id) {
        setGroupedSessions({});
        return;
      }

      const groupedByPatient = sessions.reduce(
        (
          acc: Record<string, SessionType[]>,
          session: SessionType
        ) => {
          const patientId = session.patient?._id!;
          if (!acc[patientId]) {
            acc[patientId] = [];
          }
          acc[patientId].push(session);
          return acc;
        },
        {}
      );

      setGroupedSessions(groupedByPatient);
    } catch (error) {}
  };

  const handleSaveNote = async (sessionId: string) => {
    if (selectedSession?.notes === notes) {
      setEditMode(false);
      return;
    }
  
    setLoading(true);
  
    try {
      await sessionService.updateSessionInformation(sessionId, {
        notes,
      });
  
      const updatedSession = { ...selectedSession, notes };
  
      const updatedGroupedSessions = { ...groupedSessions };
      const patientSessions = updatedGroupedSessions[selectedSession?.patient?._id!];
  
      const updatedSessions = patientSessions.map((session) =>
        session._id === sessionId ? updatedSession : session
      );
  
      updatedGroupedSessions[selectedSession?.patient?._id!] = updatedSessions;
  
      setGroupedSessions(updatedGroupedSessions);
      setSelectedSession(updatedSession);
      setEditMode(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Not eklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };
  
  const handleOpenModal = (session: SessionType) => {
    setSelectedSession(session);
    setNotes(session.notes || "");
    setOpen(true);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date.toLocaleDateString("tr-TR");
  };
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);

    return date.toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formik = useFormik({
    initialValues: {
      name_surname: "",
      email: "",
      phone_number: "",
    },
    onSubmit: async (values: Patients) => {
      try {
        await patientService.updatePatient(editPatient!, values);
        const updatedGroupedSessions = { ...groupedSessions };

        if (editPatient && updatedGroupedSessions[editPatient]) {
          updatedGroupedSessions[editPatient] = updatedGroupedSessions[
            editPatient
          ].map((session) => {
            if (session.patient && session.patient._id) {
              return {
                ...session,
                patient: {
                  ...session.patient,
                  ...values,
                  _id: session.patient._id,
                } as Patients<[], true> & { _id: string },
              };
            }
            return session;
          });
          setGroupedSessions(updatedGroupedSessions);
          setEditPatient(null);
        }
      } catch (error) {
        alert(
          "Hasta bilgileri güncellenirken bir hata oluştu. Lütfen tekrar deneyin."
        );
        console.error(error);
      }
      setEditPatient(null);
    },
  });

  const handleEditPatient = (
    event: React.MouseEvent,
    patient: Patients,
    patientId: string
  ) => {
    event.stopPropagation();

    if (editPatient === patientId) {
      setEditPatient(null);
    } else {
      formik.setValues({
        name_surname: patient?.name_surname || "",
        email: patient?.email || "",
        phone_number: patient?.phone_number || "",
      });
      setEditPatient(patientId);
    }
  };

  const patientFields = [
    { fieldKey: 'name_surname', displayer: '' },
    { fieldKey: 'email', displayer: ''},
    { fieldKey: 'phone_number', displayer: ''},
  ];

  const getFieldValue = (fieldKey: string, patient: Patients | undefined): string | undefined => {
    if (!patient) return undefined;
    return patient[fieldKey as keyof Patients];
  };


  return (
    <div className={styles["session-history"]}>
      {/* <div className={styles["add-patient-btn"]}>
        <WButton type="outlined" onClick={() => setOpenAddPatient(true)}>
          <span>+ Danışan Ekle</span>
        </WButton>
      </div> */}

      <div className={styles["content"]}>
        {Object.entries(groupedSessions)?.map(
          ([patientId, sessions], index) => (
            <WAccountCard key={patientId} className={styles["session-row"]}>
              <WAccordion
                expanded={expanded === `panel-${index}`}
                onChange={handleChangeAccordion(`panel-${index}`)}
                className={styles["accordion"]}
                disableGutters
                elevation={0}
                square
              >
                <WAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    className={`${styles["accordion-summary"]} ${
                      expanded === `panel-${index}` && styles["active"]
                    }`}
                  >
                    {patientFields.map(({ fieldKey, displayer }) => (
                           <EditableInfoRow
                           fieldKey={fieldKey}
                           displayer={displayer}
                           value={getFieldValue(fieldKey, sessions[0]?.patient)}
                           editable={editPatient === patientId}
                           formik={formik}
                           onClick={(e) => e.stopPropagation()}
                         />
                    ))}
                    {editPatient === patientId && (
                      <WButton onClick={formik.handleSubmit} borderRadius="5px">
                        Kaydet
                      </WButton>
                    )}

                    <WButton
                      onClick={(event: React.MouseEvent) => {
                        handleEditPatient(
                          event,
                          sessions[0].patient!,
                          patientId
                        );
                      }}
                      className={styles["edit-patient"]}
                      margin="0 3rem 0 0"
                      fontSize="0.75rem"
                      padding="0"
                      type="outlined"
                      borderRadius="5px"

                      borderColor={globalColors.primary}
                      color={globalColors.primary}
                    >
                      {" "}
                      {editPatient === patientId ? "Iptal" : "Düzenle"}{" "}
                    </WButton>
                  </div>
                </WAccordionSummary>

                <WAccordionDetails className={styles["accordion-details"]}>
                  <table>
                    <thead>
                      <tr>
                        <th>Video İsmi</th>
                        <th>Seansın Yapıldığı Tarih</th>
                        <th>Seansın Başlama ve Bitiş Saatleri</th>
                        <th>Seansla İlgili Notlar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sessions.map((session) => (
                        <tr key={session._id}>
                          <td>
                            <ul className={styles["session-videos"]}>
                              {session?.videos?.map((video, index) => (
                                <li
                                  key={video._id}
                                  className={styles["videos-history"]}
                                >
                                  <span>{video.title}</span>
                                  <div className={styles["video-duration"]}>
                                    <span>
                                      {utilityService.formatDate(
                                        (session?.viewing_history?.[index]
                                          ?.duration_in_ms || 0) /1000
                                      )}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>{formatDate(`${session.started_at}`)}</td>
                          <td>
                            {formatTime(`${session.started_at}`)}-{" "}
                            {formatTime(`${session.end_at}`)}
                          </td>
                          <td>
                            <div
                              className={styles["session-note"]}
                              onClick={() => handleOpenModal(session)}
                            >
                              {session.notes || "-"}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </WAccordionDetails>
              </WAccordion>
            </WAccountCard>
          )
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <>
          <WModalCard
            width="50%"
            height="50%"
            padding="20px"
            borderRadius="30px"
            className={`${styles["modal"]} flex flex-col gap-3`}
          >
            <span
              className={styles["close-modal"]}
              onClick={() => setOpen(false)}
            >
              X
            </span>
            {loading && (
              <div className={styles["loading"]}>
                <CircularProgress sx={{ color: globalColors.primary }} />
              </div>
            )}
            <>
            <span className="text-sm">Not Eklemek için düzenle butonuna tıklayın</span>
            <div className={styles["note"]}>
              {editMode ? (
                <textarea
                  className={styles["note-textarea"]}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Not eklemek için buraya yazın"
                />
              ) : (
                <>
                  <span className={styles["note-content"]}>
                    {" "}
                    {selectedSession?.notes}
                  </span>
                </>
              )}
            </div>
            </>
        

            <div className={styles["note-buttons"]}>
              <WButton
                borderRadius="10px"
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? "İptal" : "Düzenle"}
              </WButton>
              {editMode && (
                <WButton
                  borderRadius="10px"
                  onClick={() => handleSaveNote(selectedSession?._id!)}
                >
                  Kaydet
                </WButton>
              )}
            </div>
          </WModalCard>
        </>
      </Modal>
    </div>
  );
}

export default SessionHistory;
