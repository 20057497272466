import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FC } from "react";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user.user);
  const location = useLocation();

  const publicRoutes = ["/session", "/completed-remove-account"];

  const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));

  if (!user._id && !isPublicRoute) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;