import { WIconButton } from "../../../components/icon-button/icon-button";
import { WTooltip } from "../../../components/tooltip/tooltip";
import { iSessionTopBarRightItem } from "../../../interfaces/session-top-bar-right-item";
import styles from "./top-bar.module.scss";


interface iSessionUpperProps {
    onBackClick: () => any;
    title: string;
    rightItems: Array<iSessionTopBarRightItem>;
    backIcon: JSX.Element,
    rightItemsVisibility: boolean
};

function SessionTopBar({ onBackClick, title, rightItems, backIcon, rightItemsVisibility }: iSessionUpperProps) {
    return (
        <div className={styles["top-bar"]} >
            <div className={styles["left"]}>
                <WIconButton onClick={onBackClick}>
                    {backIcon}
                </WIconButton>
                <span>{title}</span>
            </div>
            {rightItemsVisibility &&
                <div className={styles["right"]}>

                    {rightItems.map((item, index) =>
                        <WTooltip key={index} title={item.tooltip}>
                            <div>
                                <WIconButton onClick={item.onClick}>
                                    {item.icon}
                                </WIconButton>
                            </div>
                        </WTooltip>
                    )}

                </div>
            }

        </div>
    )
}

export default SessionTopBar