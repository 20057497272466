import React, { useEffect, useState } from "react";
import styles from "./subscription-plans.module.scss";
import WButton from "../../button/button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProduct } from "../../../redux/payment/payment.slice";
import databaseService from "../../../services/database.service";
import { CircularProgress } from "@mui/material";
import WAccountCard from "../../account-card/account-card";
import checkIcon from "../../../assets/img/check-mark.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface MainProducts {
  month: any[];
  year: any[];
}
type SubscriptionType = keyof MainProducts;

interface SubscriptionPlansProps {
  action?: string;
}

const SubscriptionPlans = ({ action = "create" }: SubscriptionPlansProps) => {
  const [type, setType] = useState<SubscriptionType>("month");
  const [plan, setPlan] = useState<any>(); // define type later
  const [mainProducts, setMainProducts] = useState<MainProducts>({
    month: [],
    year: [],
  });
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [currentPlan, setCurrentPlan] = useState<any>();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user.user);

  action = user?.subscriptions?.price?.id ? "update" : "create";

  const handleTypeChange = (type: SubscriptionType) => {
    setType(type);
    setPlan(mainProducts[type][0]);

    const currentPlanType = user?.subscriptions?.price?.reneval_periods || "";
    if (type === currentPlanType) {
      setCurrentPlanAsDefault();
    }
  };
  const choosePlan = (plan: any) => {
    setPlan(plan);
    setSelectedPlanId(plan._id);
  };

  const subscribeToPlan = async (plan: any) => {
    dispatch(setProduct(plan));
    localStorage.setItem("selectedPlan", JSON.stringify(plan) || "");
    navigate(`/payment/${action}-subscription`);
  };

  const getAllSubscriptionPlans = async () => {
    try {
      const subscriptionPlans = await databaseService.getPlans({
        "product.product_type": "subscription",
      });
      const month = subscriptionPlans.filter(
        (plan: any) => plan?.reneval_periods === "month"
      );

      const year = subscriptionPlans.filter(
        (plan: any) => plan?.reneval_periods === "year"
      );
      setMainProducts({ month, year });
      setPlan(month[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllSubscriptionPlans();
  }, []);

  const setCurrentPlanAsDefault = () => {
    const type: SubscriptionType =
      user?.subscriptions?.price?.reneval_periods || "";
    const currentPlan = mainProducts[type].find(
      (plan: any) => user.subscriptions.price.id === plan.id
    );

    setPlan(currentPlan || mainProducts[type][0]);
    setCurrentPlan(currentPlan);
  };

  useEffect(() => {
    if (user?.subscriptions?.price?.id) {
      setCurrentPlanAsDefault();
    }
  }, [mainProducts]);

  const currencySymbol = (currency: string) => {
    switch (currency) {
      case "usd":
        return "$";
      case "eur":
        return "€";
      case "try":
        return "₺";
    }
  };

  return (
    <div className={styles["subscription-products"]}>
      {loading ? (
        <div className="overlay">
          <CircularProgress />{" "}
        </div>
      ) : (
        <div className="w-full flex flex-col gap-4">
          <div className={styles["subscription-types"]}>
            <WButton
              className={`${styles["subcription-type-button"]} ${
                type === "month" ? styles["active"] : ""
              }`}
              onClick={() => handleTypeChange("month")}
            >
              Aylık
            </WButton>
            <WButton
              className={`${styles["subcription-type-button"]} ${
                type === "year" ? styles["active"] : ""
              }`}
              onClick={() => handleTypeChange("year")}
            >
              Yıllık
            </WButton>
          </div>

          <div className="w-full flex gap-4 justify-between">
            {mainProducts[type]?.map((plan: any) => {
              const isCurrentPlan = user?.subscriptions?.price?.id === plan?.id;
              return (
                <div
                  className={`${styles["product"]} ${isCurrentPlan ? styles["current-plan"] : ""}`}
                  key={plan._id}
                  onClick={() => choosePlan(plan)}
                >
               <div>
                    <h1 className={styles["product-price"]}>
                      {plan.price}
                      {currencySymbol(plan.currency)}/
                      {plan?.reneval_periods === "month" ? "aylık" : "yıllık"}
                    </h1>
                  <div className="flex flex-col text-sm my-3">
                    <span className="font-bold">{plan?.product?.title}</span>
                    <span>
                      {plan?.reneval_periods === "month" ? "Aylık" : "Yıllık"}{" "}
                      Ödeme
                    </span>
                  </div>

                  <div className="mb-4 flex flex-col gap-3">
                      {plan?.product?.informations?.map(
                        (info: string, index: number) => (
                          <div
                            key={index}
                            className="flex items-center gap-2"
                          >
                          
                            {/* <CheckCircleOutlineIcon fontSize="small" className={`${isCurrentPlan ? "text-white" : "text-primary"}`}/> */}
                            <span className={styles["info-text"]}> - {info}</span>
                          </div>
                        )
                      )}

                  </div>
               </div>

                {
                  isCurrentPlan ? null : (
                    <WButton
                    borderRadius="5px"
                    onClick={() => subscribeToPlan(plan)}
                  >
                    satın al
                  </WButton>
                  )
                }
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlans;
