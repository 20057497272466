import styles from "./auth.module.scss";
import { Outlet } from "react-router-dom";

function Auth() {
  return (
    <div className={styles["auth"]}>
        <Outlet />
    </div>
  );
}

export default Auth;
