import React, { useEffect, useState } from "react";
import styles from "./payment.module.scss";
import WAccountCard from "../../components/account-card/account-card";
import WButton from "../../components/button/button";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setOption, setProduct } from "../../redux/payment/payment.slice";
import { useNavigate } from "react-router-dom";

function Payment() {
  
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoading, setIsloading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const product = useSelector((state: any) => state.payment.product);
  

  const checkPaymentIntent = async (): Promise<string | null> => {
    const storedPaymentIntentId = JSON.parse(
      sessionStorage.getItem("paymentIntentSession") || "null"
    );

    if (storedPaymentIntentId) {
      try {
        const response = await axios.post(
          "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/retrievePaymentIntent",
          {
            paymentIntent_id: storedPaymentIntentId?.data?.paymentIntent?.id,
            authorization: localStorage.getItem("videoPortal"),
          }
        );
        const paymentIntent = response.data;
        if (paymentIntent.metadata.productId === product.product.id) {
          if (
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "canceled"
          ) {
            sessionStorage.removeItem("paymentIntentId");
            return null;
          }
          return storedPaymentIntentId;
        }

        sessionStorage.removeItem("paymentIntentId");
        return null;
      } catch (error) {
        console.error("Error fetching PaymentIntent:", error);
        sessionStorage.removeItem("paymentIntentId");
        return null;
      }
    }

    return null;
  };

  const createPaymentIntent = async () => {
    console.log(product?.price);
    console.log(product?.currency);
    console.log(product?.product.id);
    
    const session = await axios.post(
      "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/createPaymentIntent",
      {
        authorization: localStorage.getItem("videoPortal"),
        price: product?.price,
        currency: product?.currency,
        productId: product?.product?.id,
      }
    );
    const paymentIntentSession = session.data;
    sessionStorage.setItem(
      "paymentIntentSession",
      JSON.stringify(paymentIntentSession)
    );

    return paymentIntentSession;
  };

  const initializePaymentIntent = async () => {
    const existingPaymentIntent = await checkPaymentIntent();

    if (existingPaymentIntent) {
      return existingPaymentIntent;
    } else {
      const newPaymentIntent = await createPaymentIntent();
      return newPaymentIntent;
    }
  };

  useEffect(() => {
    const handlePayment = async () => {
      setIsloading(true);

      const payment = await initializePaymentIntent();
    
      if (payment?.paymentIntent?.client_secret) {
        dispatch(
          setOption({
            clientSecret: payment.paymentIntent.client_secret,
            customerSessionClientSecret: payment?.paymentIntent?.client_secret,
          })
        );

        setClientSecret(payment.paymentIntent.client_secret);
      }
      setIsloading(false);
    };

    if (!clientSecret) {
      handlePayment();
    }
  }, [clientSecret]);

useEffect(() => {
    if (!product) {
      console.log("surekli");
      
      const selectedProduct = JSON.parse(localStorage.getItem("selectedPlan") || '{}') ;
         if (!selectedProduct) {
           navigate("/subscription");
         }
         else {
           dispatch(setProduct(selectedProduct));
         }

    }
}, [product]);

  const continuePayment = async () => {
    navigate("/create-subscription");
  }

  return (
    <div className={styles["payment"]}>
      <div className={styles["content"]}>
        <h1>Satın Alma İşlemine Devam Et</h1>
        <WAccountCard>
          <div className={styles["product"]}>
            <div className={styles["title"]}>
              <h2>{product?.product?.title}</h2>
            </div>
            <ul></ul>
            <ul className={styles["details"]}>
                {product?.product?.informations?.map(
                  (info: string, index: number) => (
                    <li key={index}>{info}</li>
                  )
                )}
              </ul>
          </div>
        </WAccountCard>
        <WButton horizontalSize={20}>Devam Et</WButton>
      </div>
    </div>
  );
}

export default Payment;
