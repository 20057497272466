class IPlayerService {

    private _player: any;
    private _currentSrcId: string | null = null;

    public get player() {
        return this._player;
    }

    public set player(player: any) {
        this._player = player;
    }

    public get currentSrcId(): string | null {
        return this._currentSrcId;
    }

    public set currentSrcId(currentSrcId: string | null) {
        this._currentSrcId = currentSrcId;
    }

    public get currentSrc() {
        return this._player.currentSrc();
    }
}

const PlayerService = new IPlayerService();
export default PlayerService;