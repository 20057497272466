import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iUser } from "../../interfaces/common";

interface iUserState {
    user: iUser;
}

const initialState: iUserState = {
    user: {},
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<iUser>) {
            state.user = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions
export const userReducer = userSlice.reducer;