import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/auth/login/login";
import Register from "./pages/auth/register/register";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password";
import Account from "./pages/account/account";
import Auth from "./pages/auth/auth";
import Invoice from "./pages/account/invoice/invoice";
import Membership from "./pages/account/membership/membership";
import Notification from "./pages/account/notification/notification";
import Profile from "./pages/account/profile/profile";
import SessionHistory from "./pages/account/session-history/session-history";
import Subscription from "./pages/account/subscription/subscription";
import Home from "./pages/home/home";
import Session from "./pages/session/session";
import SessionPreview from "./pages/session-preview/session-preview";
import Payment from "./pages/payment/payment";
import ChangePassword from "./pages/change-password/change-password";
import RemoveAccount from "./pages/auth/remove-account/remove-account";
import CreatePassowrd from "./pages/auth/create-password/create-password";
import Onboarding from "./pages/onboarding/onboarding";
import ProtectedRoute from "./protected-route/protected-route";
import CompleteRegistration from "./pages/auth/complete-registration/complete-registration";
import CompletedRemoveAccount from "./pages/auth/completed-remove-account/completed-remove-account";
import LegalTerms from "./pages/legal-terms/legal-terms";
import CreateSubscription from "./pages/create-subscription/create-subscription";
import UpdateSubscription from "./pages/account/subscription/update-subscription/update-subscription";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Onboarding />,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        ),
      },
      {
        path: "session-preview",
        element: (
          <ProtectedRoute>
            <SessionPreview />
          </ProtectedRoute>
        ),
      },
      {
        path: "session/:sessionId",
        element: <Session />,
      },
      {
        path: "payment",
        element: (
          <ProtectedRoute>
            <Payment />
          </ProtectedRoute>
        ),
      },
      {
        path: "payment/:action",
        element: (
          <ProtectedRoute>
            <CreateSubscription />
          </ProtectedRoute>
        ),
      },
      {
        path: "change-password",
        element: (
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "legal-terms/:term",
        element: (
            <LegalTerms />
        ),
      },
      {
        element: <Auth />,
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "create-password/:key",
            element: <CreatePassowrd />,
          },
          {
            path: "complete-registration/:verification-code",
            element: <CompleteRegistration />,
          },
          {
            path: "completed-remove-account/:verification-code",
            element: <CompletedRemoveAccount />,
          },
          {
            path: "remove-account",
            element: (
              <ProtectedRoute>
                <RemoveAccount />
              </ProtectedRoute>
            ),
          },
        ],
      },

      {
        element: <Account />,
        children: [
          {
            path: "invoice",
            element: (
              <ProtectedRoute>
                <Invoice />
              </ProtectedRoute>
            ),
          },
          {
            path: "membership",
            element: (
              <ProtectedRoute>
                <Membership />
              </ProtectedRoute>
            ),
          },
          {
            path: "notification",
            element: (
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            ),
          },
          {
            path: "profile",
            element: (
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            ),
          },
          {
            path: "session-history",
            element: (
              <ProtectedRoute>
                <SessionHistory />
              </ProtectedRoute>
            ),
          },
          {
            path: "subscription",
            element: (
              <ProtectedRoute>
                <Subscription />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
