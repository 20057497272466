import { FC } from "react";
import styles from "./notification.module.scss";

interface iNotificationProps {
  count?: number;
  customStyle?: string;
}

const WNotification: FC<iNotificationProps> = ({ count, customStyle }) => {
  if (!count) return null;
  return (
    <span className={`${styles["notification-count"]} ${customStyle}`}>
      {count}
    </span>
  );
};

export default WNotification;
