import React, { useEffect, useState } from "react";
import databaseService from "../../services/database.service";
import { Legal_Terms } from "../../services/spica/bucket/bucket";
import styles from "./legal-terms.module.scss";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { globalColors } from "../../style/colors";
const LegalTerms = () => {
  const [terms, setTerms] = useState<Legal_Terms[]>([]);
  const { term } = useParams<{ term: string }>();

  useEffect(() => {
    getTerms();
    window.scrollTo(0, 0);
  }, []);

  const getTerms = async () => {
    const legalTerms = await databaseService.getLegalTerms(term!);
    setTerms(legalTerms);
  };

  return (
    <>
    
      {terms.length === 0 ? (
        <div className={styles["loading"]}>
        <CircularProgress sx={{color: globalColors.primary}}/>
        </div>
      ) : (
          <div className={styles["terms"]}>
            <div
              dangerouslySetInnerHTML={{ __html: terms[0]?.content! }}
              className={styles["conent"]}
            />
          </div>
      )}
    </>
  );
};

export default LegalTerms;
