import React from "react";
import styles from "./notification-item.module.scss";
import { NotificationProps } from "../../../services/interfaces";

interface NotificationItemProps {
  notification: NotificationProps;
  isReaded: boolean;
  onClick: (notification: NotificationProps) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  isReaded,
  onClick,
}) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };
  return (
    <div
      key={notification._id}
      className={`${styles["item"]} ${
        isReaded ? styles["readed"] : styles["unreaded"]
      }`}
      onClick={() => onClick(notification)}
    >
      <div className={styles["notification-top"]}>
        <h4>{notification?.title || "Uygulama"}</h4>
        <span className={styles["notification-date"]}>
          {formatDate(notification?.created_at as string)}
        </span>
      </div>
      <span className={styles["notification-description"]}>
        {notification.description}
      </span>
    </div>
  );
};

export default NotificationItem;
