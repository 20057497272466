import React, { act, useEffect, useState } from "react";
import styles from "./notification.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { NotificationProps } from "../../../services/interfaces";

import WGlobalDialog, {
  IWGlobalDialog,
} from "../../../prefabs/global-dialog/global-dialog";
import { useNavigate } from "react-router-dom";
import {
  setNotificationMetaData,
  setNotifications,
} from "../../../redux/notification/notification.slice";
import notificationService from "../../../services/notification.service";
import WButton from "../../../components/button/button";
import NotificationItem from "../../../components/notification/notification-item/notification-item";
import { CircularProgress } from "@mui/material";
import { globalColors } from "../../../style/colors";

type NotificationsMetaData = {
  _id?: string;
  notifications: string[];
  general_unreaded_notifications: string[];
  user_specific_unreaded_notifications: string[];
};

function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readedNotifications, setReadedNotifications] = useState<
    NotificationProps[]
  >([]);
  const [unReadedNotifications, setUnReadedNotifications] = useState<
    NotificationProps[]
  >([]);

  const [visible, setIsDialogVisible] = useState<boolean>(false);

  const notificationsMetaData = useSelector(
    (state: RootState) => state.notification.notificationsMetaData
  );
  const [dialogs, setDialogs] = useState<IWGlobalDialog["dialogs"]>([
    {
      title: "Title",
      description: "Description",
      buttons: [
        {
          onClick: () => {},
          children: "Button",
        },
      ],
      isDialogVisible: visible,
      onClose: () => {},
    },
  ]);

  const [markAsReadMode, setMarkAsReadMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    setDialogs((prevDialogs) =>
      prevDialogs.map((dialog) => ({
        ...dialog,
        isDialogVisible: visible,
      }))
    );
  }, [visible]);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user.notifications) {
        await getNotificationsMetaData(user?._id!);

        // await getAllNotifications();

        try {
        } catch (error) {
          console.error("Error updating notifications:", error);
        }
      }
    };

    fetchNotifications();
  }, []);

  const getAllNotifications = async (notificationMetaData: any) => {
    console.log("getNotificationsMetaData in All ", notificationMetaData);

    if (notificationMetaData.notifications.length === 0) {
      return;
    }

    setLoading(true);
    try {
      const applicationNotifications =
        await notificationService.getNotifications({
          _id: { $in: notificationMetaData?.notifications },
        });
      const userSpecificNotification =
        await notificationService.getUserSpecificNotifications({
          _id: { $in: notificationMetaData?.notifications },
        });

      const transformedApplicationNotifications: NotificationProps[] =
        applicationNotifications.map((notification: any) => ({
          _id: notification._id,
          title: notification.title,
          description: notification.description,
          buttons: notification.buttons || [],
          created_at: new Date(notification.created_at).toISOString(),
          type: "application",
        }));

      const transformedUserSpecificNotifications: NotificationProps[] =
        userSpecificNotification.map((notification: any) => ({
          _id: notification._id,
          title: notification.title,
          description: notification.description,
          buttons: notification.buttons || [],
          created_at: new Date(notification.created_at).toISOString(),
          type: "user_specific",
        }));

      const mergedNotifications = [
        ...transformedApplicationNotifications,
        ...transformedUserSpecificNotifications,
      ];

      mergedNotifications.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );

      if (!mergedNotifications[0]._id) {
        setReadedNotifications([]);
        setUnReadedNotifications([]);
        return;
      }

      const unreaded = [
        ...(notificationMetaData.general_unreaded_notifications || []),
        ...(notificationMetaData.user_specific_unreaded_notifications || []),
      ];

      const unreadedNotifications = mergedNotifications.filter(
        (notification: NotificationProps) => unreaded.includes(notification._id)
      );

      setUnReadedNotifications(unreadedNotifications);

      const readedNotifications = mergedNotifications.filter(
        (notification: NotificationProps) =>
          !unreaded.includes(notification._id)
      );
      setReadedNotifications(readedNotifications);
      setLoading(false);
    } catch (error) {
      console.error("Error getting notifications:", error);
      setReadedNotifications([]);
      setUnReadedNotifications([]);
      setLoading(false);
    }
  };

  const buttonsCreationForDialog = (buttons: any) => {
    const mappedButtons =
      buttons?.map((button: { route: string; title: string }) => ({
        onClick: () => {
          navigate(button.route);
          setIsDialogVisible(false);
        },
        children: button.title,
      })) || [];

    mappedButtons.push({
      onClick: () => {
        setIsDialogVisible(false);
      },
      children: "Close",
    });
    return mappedButtons;
  };

  const handleNotificationClick = async (notification: NotificationProps) => {
    const buttons = buttonsCreationForDialog(notification?.buttons);

    setIsDialogVisible(true);
    setDialogs([
      {
        title: "",
        description: notification?.description,
        buttons: buttons,
        isDialogVisible: visible,
        onClose: () => {
          setIsDialogVisible(false);
        },
      },
    ]);

    if (readedNotifications.some((n) => n._id === notification._id)) {
      return;
    }

    readNotificationCommon(notification);
  };

  const readNotificationCommon = async (notification: NotificationProps) => {
    const id = notification._id;
    const type = notification.type;

    if (type === "application") {
      const updateUnreadedNotification =
        notificationsMetaData.general_unreaded_notifications.filter(
          (notificationId: string) => notificationId !== id
        );
      console.log("updateUnreadedNotification", updateUnreadedNotification);
      try {
        await notificationService.updateNotificationMetaData(
          notificationsMetaData._id!,
          {
            general_unreaded_notifications: updateUnreadedNotification,
          }
        );
        dispatch(setNotificationMetaData(updateUnreadedNotification));
      } catch (error) {
        console.error("Error updating notifications:", error);
      }
    } else if (type === "user_specific") {
      const updateUnreadedNotification =
        notificationsMetaData.user_specific_unreaded_notifications.filter(
          (notificationId: string) => notificationId !== id
        );
      try {
        await notificationService.updateNotificationMetaData(
          notificationsMetaData._id!,
          {
            user_specific_unreaded_notifications: updateUnreadedNotification,
          }
        );
        dispatch(setNotificationMetaData(updateUnreadedNotification));
      } catch (error) {
        console.error("Error updating notifications:", error);
      }
    }

    const updatedUnreadedNotifications = unReadedNotifications.filter(
      (item: NotificationProps) => item._id !== id
    );
    setUnReadedNotifications(updatedUnreadedNotifications);
    const updatedReadedNotifications = [...readedNotifications, notification];
    setReadedNotifications(updatedReadedNotifications);

    dispatch(setNotifications(updatedUnreadedNotifications));
  };

  const [selectedNotifications, setSelectedNotifications] = useState<
    NotificationProps[]
  >([]);

  const handleCheckboxChange = (notification: NotificationProps) => {
    if (selectedNotifications.some((n) => n._id === notification._id)) {
      setSelectedNotifications((prevSelectedNotifications) =>
        prevSelectedNotifications.filter((n) => n._id !== notification._id)
      );
    } else {
      setSelectedNotifications((prevSelectedNotifications) => [
        ...prevSelectedNotifications,
        notification,
      ]);
    }
  };

  const getNotificationsMetaData = async (userId: string) => {
    try {
      const notificationsMetaData =
        await notificationService.getNotificationsMetaData(userId);

      getAllNotifications(notificationsMetaData[0]);

      dispatch(setNotificationMetaData(notificationsMetaData[0]));

      dispatch(
        setNotifications([
          ...(notificationsMetaData[0]?.general_unreaded_notifications || []),
          ...(notificationsMetaData[0]?.user_specific_unreaded_notifications ||
            []),
        ])
      );
    } catch (error) {
      console.error(error);
    }
  };
  const selectAllNotifications = () => {
    setSelectedNotifications([
      ...unReadedNotifications,
      ...readedNotifications,
    ]);
  };

  const selectAllNotificationsForRead = () => {
    setSelectedNotifications([...unReadedNotifications]);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    setSelectedNotifications([]);
  };
  const handleMarkAsReadMode = () => {
    setMarkAsReadMode(!markAsReadMode);
    setSelectedNotifications([]);
  };

  const updateNotifications = async (actionType: string) => {
    const selectedIds = selectedNotifications.map((n) => n._id);

    const updatedNofications = notificationsMetaData.notifications.filter(
      (id: string) => !selectedIds.includes(id)
    );

    const generalUnreadedNotifications =
      notificationsMetaData.general_unreaded_notifications.filter(
        (id: string) => !selectedIds.includes(id)
      );

    const userSpecificUnreadedNotifications =
      notificationsMetaData.user_specific_unreaded_notifications.filter(
        (id: string) => !selectedIds.includes(id)
      );

    dispatch(
      setNotificationMetaData({
        ...notificationsMetaData,
        general_unreaded_notifications: generalUnreadedNotifications,
        user_specific_unreaded_notifications: userSpecificUnreadedNotifications,
      })
    );
    dispatch(
      setNotifications([
        ...generalUnreadedNotifications,
        ...userSpecificUnreadedNotifications,
      ])
    );

    let updatedFiled = {};

    if (actionType === "delete") {
      updatedFiled = {
        general_unreaded_notifications: generalUnreadedNotifications,
        user_specific_unreaded_notifications: userSpecificUnreadedNotifications,
        notifications: updatedNofications,
      };

      updatingNotificationMetaDataBucket(updatedFiled);
      const updatedReadedNotifications = readedNotifications.filter(
        (item: NotificationProps) => !selectedIds.includes(item._id as string)
      );

      setReadedNotifications(updatedReadedNotifications);
    } else if (actionType === "read") {
      updatedFiled = {
        general_unreaded_notifications: generalUnreadedNotifications,
        user_specific_unreaded_notifications: userSpecificUnreadedNotifications,
      };

      updatingNotificationMetaDataBucket(updatedFiled);

      const updatedReadedNotifications = [
        ...readedNotifications,
        ...selectedNotifications,
      ];

      setReadedNotifications(updatedReadedNotifications);
    }

    const updatedUnreadedNotifications = unReadedNotifications.filter(
      (item: NotificationProps) => !selectedIds.includes(item._id as string)
    );

    setUnReadedNotifications(updatedUnreadedNotifications);

    closeMode();
  };

  const updatingNotificationMetaDataBucket = async (updatedFiled: any) => {
    try {
      await notificationService.updateNotificationMetaData(
        notificationsMetaData._id!,
        updatedFiled
      );
    } catch (error) {
      console.error("Error updating notifications:", error);
    }
  };

  const handleAction = (action: string) => {
    updateNotifications(action);
    closeMode();
  };

  const closeMode = () => {
    setEditMode(false);
    setMarkAsReadMode(false);
    setSelectedNotifications([]);
  };

  return (
    <div className={styles["notification"]}>
      {loading ? (
        <div className={styles["loading"]}>
          <CircularProgress sx={{ color: globalColors.primary }} />
        </div>
      ) : (
        <>
          {unReadedNotifications.length === 0 &&
          readedNotifications.length === 0 ? (
            <div className={styles["empty-notification"]}>
              <h1>Henüz bildirim yok</h1>
            </div>
          ) : (
            <>
              {(unReadedNotifications?.length > 0 ||
                readedNotifications?.length > 0) && (
                <div className={styles["buttons"]}>
                  <WButton
                    type="primary"
                    borderRadius="5px"
                    onClick={handleEditMode}
                    disabled={markAsReadMode}
                  >
                    {editMode ? "Iptal et" : "Düzenle"}
                  </WButton>
                  <WButton
                    type="outlined"
                    borderRadius="5px"
                    onClick={handleMarkAsReadMode}
                    disabled={unReadedNotifications.length === 0 || editMode}
                  >
                    {markAsReadMode ? "Iptal et" : "Okundu Olarak İşaretle"}
                  </WButton>

                  {(editMode || markAsReadMode) && (
                    <WButton
                      onClick={() => {
                        editMode
                          ? selectAllNotifications()
                          : selectAllNotificationsForRead();
                      }}
                    >
                      {" "}
                      Hepsini Seç{" "}
                    </WButton>
                  )}
                  {selectedNotifications.length > 0 && editMode && (
                    <WButton onClick={() => handleAction("delete")}>
                      {" "}
                      Sil{" "}
                    </WButton>
                  )}
                  {selectedNotifications.length > 0 && markAsReadMode && (
                    <WButton onClick={() => handleAction("read")}>
                      {" "}
                      Oku{" "}
                    </WButton>
                  )}

                  {}
                </div>
              )}
              {unReadedNotifications.map((notification) => (
                <label
                  key={notification._id}
                  className={styles["notification-field"]}
                >
                  {(editMode || markAsReadMode) && (
                    <input
                      type="checkbox"
                      checked={selectedNotifications.some(
                        (n) => n._id === notification._id
                      )}
                      onChange={() => handleCheckboxChange(notification)}
                    />
                  )}
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    isReaded={false}
                    onClick={handleNotificationClick}
                  />
                </label>
              ))}

              {readedNotifications.map((notification) => (
                <label
                  key={notification._id}
                  className={styles["notification-field"]}
                >
                  {editMode && (
                    <input
                      type="checkbox"
                      checked={selectedNotifications.some(
                        (n) => n._id === notification._id
                      )}
                      onChange={() => handleCheckboxChange(notification)}
                    />
                  )}
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    isReaded={true}
                    onClick={handleNotificationClick}
                  />
                </label>
              ))}

              <WGlobalDialog dialogs={dialogs} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Notification;
