import { CSSProperties, FC } from "react";
import { globalColors } from "../../style/colors";

interface iCardTitleProps {
  color?: string;
  fontSize?: string;
  width?: string;
  padding?: string;
  margin?: string;
  children?: string;
}

const WCardTitle: FC<iCardTitleProps> = ({
  children,
  color = globalColors.lightFontColor,
  fontSize,
  width,
  padding,
  margin,
}) => {
  const cardTitleStyles: CSSProperties = {
    color,
    fontSize,
    width,
    padding,
    margin,
  };

  return <h1 style={cardTitleStyles}>{children}</h1>;
};

export default WCardTitle;
