import React from "react";
import styles from "./complete-registration.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import WTextButton from "../../../components/text-button/text-button";
import { globalColors } from "../../../style/colors";
import { useNavigate } from "react-router-dom";

function CompleteRegistration() {
  const navigate = useNavigate();

  return (
    <div className={styles["complete-registration"]}>
      <WModalCard
        borderRadius="10px"
        width="50%"
        padding="2rem 0"
        className={styles["modal"]}
      >
        <span> Email doğrulaması başarıyla gerçekleştirildi. </span>
        <WTextButton
          underline
          color={globalColors.lightFontColor}
          onClick={() => navigate("/login")}
          hoverColor={globalColors.primary}
        >
          Giriş Yap
        </WTextButton>
      </WModalCard>
    </div>
  );
}

export default CompleteRegistration;
