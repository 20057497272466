import { ChangeEvent, FC } from "react";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material";
import { globalColors } from "../../style/colors";

interface iInputProps {
  type?: "text" | "number" | "password" | "email" | "tel" | "date" | "time";
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  bgColor?: string;
  padding?: string;
  fontSize?: string;
  defaultValue?: string;
  value: number | string | undefined;
  color?: string;
  className?: string;
  maxLength?: number;
  id?: string;
  placeHolderColor?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const WInput: FC<iInputProps> = ({
  type = "text",
  required,
  disabled,
  bgColor = "transparent",
  color = globalColors.lightFontColor,
  fontSize = "16px",
  placeholder,
  defaultValue,
  value,
  className,
  maxLength,
  id,
  onChange,
  placeHolderColor
}) => {
  const specificStyles: SxProps = {
    background: bgColor,
    borderRadius: "5px",
    color,
    fontSize,
    "& ::-webkit-input-placeholder": {
      color: globalColors.primary,
      opacity: 1
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      padding: "10px 15px",
      color,
    },
    border: `1px solid ${globalColors.lightFontColor}`,
    'input': {
      caretColor: color,
      '&::placeholder': {
        color: placeHolderColor || globalColors.lightFontColor
      },
      '&:-webkit-autofill': {
        transition: 'background-color 5000s ease-in-out 0s',
        backgroundColor: `${bgColor} !important`,
        WebkitTextFillColor: `${color} !important`,
      },
    },
    

  };

  return (
    <TextField
      type={type}
      required={required}
      defaultValue={defaultValue}
      disabled={disabled}
      sx={specificStyles}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      inputProps={{ maxLength: maxLength }}
      id={id}
    ></TextField>
  );
};

export default WInput;
