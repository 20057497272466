class UtilityService {
  formatDate(seconds: number) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const formattedHours = hrs > 0 ? `${hrs}:` : "";
    const formattedMinutes =
      hrs > 0 ? String(mins).padStart(2, "0") : String(mins);
    const formattedSeconds = String(secs).padStart(2, "0");

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
  }
}

const utilityService = new UtilityService();
export default utilityService;
