import React, { useEffect, useState } from "react";
import styles from "./membership.module.scss";
import WAccountCard from "../../../components/account-card/account-card";
import WButton from "../../../components/button/button";

import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import subscriptionService from "../../../services/subscription.service";
import { useDispatch, useSelector } from "react-redux";
import { Stripe_Subscriptions } from "../../../services/interfaces";

import CancelSubscription from "../../../components/subscription/cancel-subscription/cancel-subscription";
import WModalCard from "../../../components/modal-card/modal-card";
import SubscriptionPlans from "../../../components/subscription/subscription-plans/subscription-plans";
import { WModal } from "../../../components/wrapper/wrapper";
import PaymentMethods from "../../../components/payment-methods/payment-methods";
import AddPaymentMethod from "../../../components/add-payment-method/add-payment-method";
import { CircularProgress } from "@mui/material";
import { setProduct } from "../../../redux/payment/payment.slice";
import { RootState } from "../../../redux/store";
import { globalColors } from "../../../style/colors";

const Membership = () => {
  const [loading, setLoading] = useState(true);
  const [addPaymentActive, setAddPaymentActive] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<
    Stripe_Subscriptions<
      ["price.product", "payment_method", "updated_price.product"],
      true
    >
  >({});

  const [open, setOpen] = useState<boolean>(false);

  const [cancelMembershipsPopup, setCancelMembershipsPopup] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.user);

  const handleMembership = async () => {
    try {
      if (!user?.subscriptions) {
        setSubscription({});
        return;
      }
      const subscription = await subscriptionService.getSubscriptionInformation(
        user?.subscriptions._id
      );
      setSubscription(subscription);
    } catch (error) {
      console.error(error);
      setSubscription({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleMembership();
  }, []);

  const retryPayment = async () => {
    dispatch(setProduct(subscription?.price));
    localStorage.setItem(
      "selectedPlan",
      JSON.stringify(subscription.price) || ""
    );
    navigate(`/payment/retry-subscription`);
  };

  return (
    <div className={styles["membership"]}>
      {loading ? (
        <div className="overlay">
          <CircularProgress />
        </div>
      ) : (
        <>
          {subscription?._id ? (
            <div className={styles["content"]}>
              <WAccountCard
                className={`${styles["plan"]} flex items-center justify-between`}
              >
                <div className={styles["section"]}>
                  <div className={styles["section-title"]}>
                    <span>
                      {" "}
                      {subscription?.price?.product?.title}:{" "}
                      {subscription?.price?.product?.description}
                    </span>
                    <p className="text-primary">
                      Seans sayısı -{" "}
                      {user?.one_time_session_count! +
                        user?.remaining_sessions!}
                    </p>
                  </div>
                  {!subscription.updated_price && (
                    <span>
                      Bir sonraki ödeme:{" "}
                      {new Date(subscription?.next_payment!).toLocaleDateString(
                        "tr-TR"
                      )}
                    </span>
                  )}
                  <span>
                    **** **** **** {subscription?.payment_method?.last4}
                  </span>
                </div>

                {subscription?.updated_price && (
                  <>
                    <ArrowRightAltIcon
                      sx={{ fontSize: "100px", color: "grey" }}
                    />
                    <div className="flex flex-col opacity-70  border p-2 rounded-lg border-primary">
                      <div className="flex flex-col">
                        <span>
                          {" "}
                          {subscription.updated_price.product?.title}:{" "}
                          {subscription?.updated_price.product?.description}
                        </span>
                        <span className="text-primary">
                          Başlangıç seans sayısı -{" "}
                          {subscription.updated_price.product?.session_count!}
                        </span>
                      </div>
                      <span>
                        Bir sonraki ödeme:{" "}
                        {new Date(
                          subscription?.next_payment!
                        ).toLocaleDateString("tr-TR")}
                      </span>
                      <span>
                        **** **** **** {subscription?.payment_method?.last4}
                      </span>
                    </div>
                  </>
                )}

                {subscription?.is_active && (
                  <div className={styles["membership-action-buttons"]}>
                    <WButton
                      borderRadius="5px"
                      className={styles["button"]}
                      onClick={() => setOpen(true)}
                    >
                      Planı Değiştir
                    </WButton>
                    <WButton
                      borderRadius="5px"
                      type="outlined"
                      className={styles["button"]}
                      color={globalColors.danger}
                      borderColor={globalColors.borderColor}
                      onClick={() => setCancelMembershipsPopup(true)}
                    >
                      Üyeliği İptal Et
                    </WButton>
                  </div>
                )}
                {!subscription?.is_active && (
                  <>
                    {subscription?.will_be_cancelled ? (
                      <span>
                        Üyeliğinizi iptal ettiniz.{" "}
                        {new Date(
                          subscription?.will_be_cancelled
                        ).toLocaleDateString("tr-TR")}{" "}
                        tarihine kadar eski üyeliğinizin özelliklerini
                        kullanmaya devam edebilirsiniz.{" "}
                      </span>
                    ) : (
                      <div className="flex flex-col gap-1">
                        <span>
                          Ödeme gerçekleştirilemediği için üyeliğiniz askıya
                          alındı.
                        </span>
                        <p
                          className="underline text-sm text-primary"
                          onClick={retryPayment}
                        >
                          {" "}
                          Ödeme Yap
                        </p>
                      </div>
                    )}
                  </>
                )}
              </WAccountCard>
            </div>
          ) : (
            <WAccountCard>
              <div className={styles["not-a-member"]}>
                <p> Herhangi bir üyelik bulunamadı.</p>
                <WButton
                  type="outlined"
                  color={globalColors.primary}
                  borderColor={globalColors.borderColor}
                  borderRadius="10px"
                  onClick={() => navigate("/subscription")}
                >
                  Hemen Üye Ol
                </WButton>
              </div>
            </WAccountCard>
          )}

          <WModal
            open={open}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClose={() => setOpen(false)}
          >
            <WModalCard
              width="70%"
              padding="20px"
              borderRadius="30px"
              bgColor="white"
              type="white"
            >
              <SubscriptionPlans action="update" />
            </WModalCard>
          </WModal>

          <div className={`${styles["payment-methods"]} flex flex-col gap-4`}>
            <div
              className={`${styles["title"]} flex justify-between pr-[27px]`}
            >
              <h1>Kartlarım</h1>
              <div className={`${styles["membership-action-buttons"]} pl-[7px]`}>
              <WButton
                borderRadius="5px"
                onClick={() => {
                  setAddPaymentActive(true);
                }}
                className={styles["button"]}
              >

                + Yeni Kart Ekle
              </WButton>
              </div>
        
            </div>
            <PaymentMethods />
          </div>

          <WModal
            open={addPaymentActive}
            onClose={() => {
              setAddPaymentActive(false);
            }}
          >
            <WModalCard
              width="40%"
             
              borderRadius="30px"
              bgColor="white"
              className={styles["add-payment-method-card"]}
            >
              <AddPaymentMethod />
            </WModalCard>
          </WModal>

          <CancelSubscription
            isPopupActive={cancelMembershipsPopup}
            onClose={() => setCancelMembershipsPopup(false)}
            setSubscription={setSubscription}
            subscription={subscription}
          />
        </>
      )}
    </div>
  );
};

export default Membership;
