import { useState } from "react";

interface PasswordVisibilityState {
    currentPassword?: boolean;
    newPassword?: boolean;
    confirmPassword?: boolean;
    password?: boolean;
};

const usePasswordVisibility = () => {
    const [visibility, setVisibility] = useState<PasswordVisibilityState>({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
        password: false,
    });

    const showPassword = (id: keyof PasswordVisibilityState) => {
        setVisibility((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    return { visibility, showPassword };
};

export default usePasswordVisibility;
