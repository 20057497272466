import React, { FC } from "react";
import { WMenuItem } from "../menu-item/menu-item";
import styles from "./select-item.module.scss";
import { FormControl, InputLabel, Select } from "@mui/material";

export const defaultItem: item = { _id: "", name: "" };

export type item = {
  _id: string;
  name: string;
};

interface iSelectItem {
  items: item[];
  value: item;
  setValue: (state: item) => void;
}

const WSelectItem: FC<iSelectItem> = ({ items, value, setValue }) => {
  return (
    <FormControl>
      <InputLabel className={styles["label"]}>Danışan Seç</InputLabel>
      <Select
        value={value._id}
        onChange={(e: any) => {
          const item = items.find((item) => item._id === e.target.value) || defaultItem;
          setValue(item);
        }}
        className={styles["select"]}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
          },
        }}
      >
        {items.map((item) => (
          <WMenuItem key={item._id} value={item._id} className={styles["item"]}>
            {item.name}
          </WMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default WSelectItem;
