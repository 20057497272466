import React, { useState } from "react";

import axios from "axios";
import { CircularProgress } from "@mui/material";
import WButton from "../../button/button";
import { WModal } from "../../wrapper/wrapper";
import WModalCard from "../../modal-card/modal-card";
import { useDispatch, useSelector } from "react-redux";
import cancelIcon from "../../../assets/img/cancel.svg";
import { RootState } from "../../../redux/store";
import { globalColors } from "../../../style/colors";

interface CancelSubscriptionProps {
  isPopupActive?: boolean;
  onClose?: () => void;
  setSubscription?: React.Dispatch<React.SetStateAction<any>>;
  subscription?: any;
}
const CancelSubscription = ({
  isPopupActive = false,
  onClose,
  setSubscription,
  subscription,
}: CancelSubscriptionProps) => {
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [cancelMembershipsPopup, setCancelMembershipsPopup] =
    useState<boolean>(isPopupActive);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const cancelSubscription = async () => {
    setIsCancelling(true);
    try {
      await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/unsubscribe",
        {
          authorization: localStorage.getItem("videoPortal"),
        }
      );
      // setCancelMembershipsPopup(false);
      // handleMembership();
      setSubscription &&
        setSubscription({
          ...subscription,
          is_active: false,
          will_be_cancelled: subscription.next_payment,
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsCancelling(false);
      onClose && onClose();
    }
  };

  return (
    <WModal
      open={isPopupActive}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClose={onClose}
    >
      <WModalCard
        width="40%"
        borderRadius="10px"
        bgColor="white"
        className="flex flex-col gap-3 items-center"
        type="white"
      >
        <div className="flex flex-col items-center gap-2 mt-2">
          <img src={cancelIcon} alt="" />
          <h2 className="text-center">
            Üyeliğinizi tek bir tıklamayla iptal edebilir veya
            değiştirebilirsiniz
          </h2>
        </div>
        <div>
          Tercihiniz ne olursa olsun, bu işlem
          <b style={{ color: "red" }}>
            {" "}
            {new Date(subscription?.next_payment).toLocaleDateString()}{" "}
          </b>
          tarihinde geçerli olacaktır. Bu tarihe kadar üyeliğinizi kullanmaya
          devam edebilirsiniz.
        </div>
        <div className="flex gap-2  justify-center mb-4">
          <WButton
            onClick={cancelSubscription}
            type="outlined"
            borderRadius="5px"
            color={globalColors.primary}
            borderColor={globalColors.borderColor}
          >
            <div className="w-[200px] flex items-center justify-center">
              {" "}
              {isCancelling ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "İptal İşlemini Tamamla"
              )}
            </div>
          </WButton>
          <WButton
            borderRadius="5px"
            onClick={onClose}
            borderColor={globalColors.borderColor}
          >
            Kullanmaya Devam Et
          </WButton>
        </div>
      </WModalCard>
    </WModal>
  );
};

export default CancelSubscription;
