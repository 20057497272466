import styles from "./completed-remove-account.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import { useEffect } from "react";
import authService from "../../../services/auth.service";
function CompletedRemoveAccount() {
  useEffect(() => {
    const token = authService.getToken();
    if (token) {
      localStorage.clear();
    }
  }, []);

  return (
    <div className={styles["completed-remove-account"]}>
      <WModalCard borderRadius="10px">
        <h3> Hesabınız başarıyla silinmiştir </h3>
      </WModalCard>
    </div>
  );
}

export default CompletedRemoveAccount;
