import styles from "./register.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import WCardTitle from "../../../components/card-title/card-title";
import WTextButton from "../../../components/text-button/text-button";
import WInput from "../../../components/input/input";
import WButton from "../../../components/button/button";
import { globalColors } from "../../../style/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import authService from "../../../services/auth.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WCircularProgress } from "../../../components/progress/circular/circular-progress";

function Register() {
  const emptyInputErrorMessage = "*Bu alan boş bırakılamaz.";
  const [isNotificationChecked, setIsNotificationChecked] =
    useState<boolean>(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] =
    useState<boolean>(false);
  const [errorMessagege, setErrorMessagege] = useState<string>("");
  const [step, setStep] = useState<string>("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      password: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setStep("1");
      if (!isSubscriptionChecked) {
        return;
      }
      await authService
        .registerUser({
          notifications: isNotificationChecked,
          ...values,
        })
        .then((res: any) => {
          setStep("2");
        })
        .catch((error: any) => {
          setStep("3");
          setErrorMessagege("Bu e-posta adresi zaten kullanılıyor.");
          resetForm();
        });

      setIsSubscriptionChecked(false);
      setIsNotificationChecked(false);
      resetForm();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(emptyInputErrorMessage),
      surname: Yup.string().required(emptyInputErrorMessage),
      email: Yup.string()
        .email("Geçersiz e-posta adresi")
        .required(emptyInputErrorMessage),
      password: Yup.string()
        .min(8, "Şifre en az 8 karakter olmalı, harf ve sayı içermelidir.")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)/,
          "Şifreniz harf ve sayı içermelidir."
        )
        .required("*Şifre boş bırakılamaz."),
    }),
  });

  const handleIsNotificationCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsNotificationChecked(event.target.checked);
  };
  const handleSubscriptionCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSubscriptionChecked(event.target.checked);
  };

  const isFormFilled =
    formik.values.name !== "" &&
    formik.values.surname !== "" &&
    formik.values.email !== "" &&
    formik.values.password !== "";

  return (
    <>
      <WModalCard borderRadius="10px" padding="15px 30px">
        {step !== "2" && (
          <div className={styles["register"]}>
            <WCardTitle  margin="0">
              Kayıt Ol
            </WCardTitle>

            {errorMessagege && (
              <span>
                <span>{errorMessagege}</span>
                <WTextButton
                  color={globalColors.primary}
                  fontSize="0.75rem"
                  underline
                >
                  Lütfen Giriş Yapın.
                </WTextButton>
              </span>
            )}

            <form className={styles["register-form"]}>
              <div className={styles["input-container"]}>
                <WInput
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                  placeholder="İsim"
                ></WInput>
                {formik.touched.name && formik.errors.name ? (
                  <span className={styles["error"]}>{formik.errors.name}</span>
                ) : null}
              </div>
              <div className={styles["input-container"]}>
                <WInput
                  type="text"
                  value={formik.values.surname}
                  onChange={formik.handleChange("surname")}
                  placeholder="Soyisim"
                ></WInput>
                {formik.touched.surname && formik.errors.surname ? (
                  <span className={styles["error"]}>
                    {formik.errors.surname}
                  </span>
                ) : null}
              </div>
              <div className={styles["input-container"]}>
                <WInput
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  placeholder="E-posta"
                ></WInput>
                {formik.touched.email && formik.errors.email ? (
                  <span className={styles["error"]}>{formik.errors.email}</span>
                ) : null}
              </div>
              <div className={styles["input-container"]}>
                <WInput
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  placeholder="Şifre"
                ></WInput>
                {formik.touched.password && formik.errors.password ? (
                  <span className={styles["error"]}>
                    {formik.errors.password}
                  </span>
                ) : null}
              </div>

              <WButton
                type="primary"
                disabled={!(isFormFilled && isSubscriptionChecked)}
                onClick={formik.handleSubmit}
                bgHover="rgb(238 61 0)"
                color={globalColors.lightFontColor}
              >
                <>{step === "1" ? <WCircularProgress sx={{color:"white"}} size="2rem"/> : <>Kayıt Ol</>}</>
              </WButton>
              {isFormFilled && !isSubscriptionChecked && (
                <div className={styles["error"]}>
                  Lütfen üyelik sözleşmesini onaylayınız.
                </div>
              )}
            </form>

            <div className={styles["login-from-register"]}>
              <span>Kayıtlı bir hesabınız var ise. </span>
              <WTextButton
                onClick={() => {
                  navigate("/login");
                }}
                color={globalColors.lightFontColor}
                fontSize="0.75rem"
                hoverColor={globalColors.primary}
              >
                Oturum Açın
              </WTextButton>
            </div>

            <div className={styles["register-footer"]}>
              <div className={styles["confirmation"]}>
                <input
                  type="checkbox"
                  onChange={handleIsNotificationCheckboxChange}
                  checked={isNotificationChecked}
                />
                <span>
                  {" "}
                  Bülten haberlerini e-posta yoluyla almayı onaylıyorum.
                </span>
              </div>
              <div className={styles["confirmation"]}>
                <input
                  type="checkbox"
                  onChange={handleSubscriptionCheckboxChange}
                  checked={isSubscriptionChecked}
                />
                <span>Üyelik Sözleşmesi’ni okudum, onaylıyorum.</span>
              </div>
            </div>
          </div>
        )}
        {step === "2" && (
          <span className="text-white">
            Kaydınızı tamamlamak için e-posta adresinize gönderilen linke
            tıklayınız.
          </span>
        )}
      </WModalCard>
    </>
  );
}

export default Register;
