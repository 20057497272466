import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./invoice.module.scss";
import {
  WTable,
  WTableBody,
  WTableCell,
  WTableContainer,
  WTableHead,
  WTablePagination,
  WTableRow,
} from "../../../components/table";
import databaseService from "../../../services/database.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import axios from "axios";
import { CircularProgress } from "@mui/material";

interface iColumn {
  id: "date" | "period" | "paymentMethod" | "total";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly iColumn[] = [
  { id: "date", label: "Tarih", minWidth: 100 },
  { id: "period", label: "Hizmet Dönemi", minWidth: 100 },
  { id: "paymentMethod", label: "Ödeme Yöntemi", minWidth: 100 },
  { id: "total", label: "Toplam", minWidth: 100 },
];

interface iData {
  id: string;
  date: string;
  period: string;
  paymentMethod: string;
  total: string;
}

function Invoice() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createData = (
    id: string,
    date: string,
    period: string,
    paymentMethod: string,
    total: string
  ) => {
    return { id, date, period, paymentMethod, total };
  };

  const [invoices, setInvoices] = useState<iData[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.user.user);

  const formatDateString = (dateString: any): string => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const getInvoices = async () => {
    setLoading(true);
     const invoices = await databaseService.getInvoices();

      const rows = [];

      for (const invoice of invoices) {
        rows.push(
          createData(
            invoice?._id!,
            formatDateString(invoice?.created_at),
            `${formatDateString(
              invoice?.created_at
            )} - ${ invoice.ended_at ? formatDateString(invoice?.ended_at) : "x"}`,
            `**** **** **** ${invoice?.payment_method?.last4}`,
            invoice?.total_amount! + " TL"
          )
        );
      }
      setInvoices(rows);
      setLoading(false);

  };
  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div className={styles["invoice"]}>
      {loading ? (
        <div className="overlay">
          <CircularProgress />
        </div>
      ) : (
        <>
       { invoices.length>0 ? <>
        <WTableContainer className={styles["table-container"]}>
            <WTable stickyHeader aria-label="sticky table">
              <WTableHead>
                <WTableRow>
                  {columns.map((column, index) => (
                    <WTableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </WTableCell>
                  ))}
                </WTableRow>
              </WTableHead>
              <WTableBody>
                {invoices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: iData) => {
                    return (
                      <WTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <WTableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </WTableCell>
                          );
                        })}
                      </WTableRow>
                    );
                  })}
              </WTableBody>
            </WTable>
          </WTableContainer>
          <WTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
       </> : <div className={styles["no-invoice"]}>Fatura bulunamadı.</div>}
        </>
      )}
    </div>
  );
}

export default Invoice;
