import React, { useEffect, useState } from "react";
import styles from "./account.module.scss";
import { Outlet } from "react-router-dom";
import WSideBar from "../../prefabs/side-bar/side-bar";

const pages = [
  {
    path: "profile",
    title: "Hesap",
  },
  {
    path: "membership",
    title: "Üyelik",
  },
  {
    path: "subscription",
    title: "Planlarımı Yönet",
  },
  {
    path: "session-history",
    title: "Seanslar",
  },
  {
    path: "invoice",
    title: "Ödeme Geçmişi",
  },
  {
    path: "notification",
    title: "Bildirimler",
  },
  {
    path: "remove-account",
    title: "Hesabı Sil",
  },
];

const payment = {
  path:"create-subscription",
  title: "Ödeme Sayfası"
}



function Account() {
  const path = window.location.pathname.split("/").pop();

  const [currentPath, setCurrentPath] = useState<string>(path!);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentPath(path!);
  }, [window.location.pathname]);
  
  

  const currentPage = pages.find(el => el.path === currentPath)
  const [title, setTitle] = useState(currentPage?.title);

  return (
    <div className={styles["account"]}>
      <div className={styles["content"]}>
          <WSideBar pages={pages} pathOnChanged={(title) => setTitle(title)} />
        <div className={styles["outlet-container"]}>
          {currentPath === payment?.path ? <h1>{payment?.title}</h1>: <h1>{title}</h1>}
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Account;
