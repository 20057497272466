import React from "react";
import styles from "./remove-account.module.scss";
import WModalCard from "../../../components/modal-card/modal-card";
import warning from "../../../assets/img/warning.svg"

function RemoveAccount() {
  return (
    <div className={styles["remove-account"]}>
      <WModalCard borderRadius="10px" className="flex flex-col items-center">
        <img src={warning} alt="" />
        <div className={styles["content"]}>
          <p>Hesabınızın silinebilmesi için lütfen mailinizi kontrol edin.</p>
        </div>
      </WModalCard>
    </div>
  );
}

export default RemoveAccount;
