import React, { useEffect, useState } from "react";
import styles from "./payment-methods.module.scss";
import {
  Payment_Methods,
  payment_methods,
} from "../../services/spica/bucket/bucket";
import WModalCard from "../modal-card/modal-card";
import { WModal } from "../wrapper/wrapper";
import WButton from "../button/button";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, colors, Popper } from "@mui/material";
import { globalColors } from "../../style/colors";
interface PaymentMethodsProps {
  filter?: any;
}

const PaymentMethods = ({ filter }: PaymentMethodsProps) => {
  const [paymentMethods, setPaymentMethods] = useState<Payment_Methods[]>([]);

  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isSettingAsDefault, setIsSettingAsDefault] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;


  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<Payment_Methods>({});

  const getPaymentMethods = async (filter: any = {}) => {
    const paymentMethods = await payment_methods.filter(filter).getAll();

    paymentMethods.sort((a, b) => {
      const aIsDefault = a.is_default ? 1 : 0;
      const bIsDefault = b.is_default ? 1 : 0;

      return bIsDefault - aIsDefault;
    });
    setPaymentMethods(paymentMethods);
  };

  useEffect(() => {
    getPaymentMethods(filter);
  }, [filter]);

  const selectPaymentMethod = (paymentMethod: Payment_Methods) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const removePaymentMethod = async (paymentMethod: Payment_Methods) => {
    setIsDeleting(true);
    try {
      await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/detachPaymentMethod",
        {
          authorization: localStorage.getItem("videoPortal"),
          paymentMethod,
        }
      );
      setPaymentMethods(paymentMethods.filter(method => method._id !== paymentMethod._id));

    } catch (error) {
      console.error("Error setting default payment method:", error);
    }
    finally {
      setAnchorEl(null);
      setIsDeleting(false);
    }
  };

  const setDefaultPaymentMethod = async () => {
    setIsSettingAsDefault(true);
    try {
      await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/setDefaultPaymentMethod",
        {
          authorization: localStorage.getItem("videoPortal"),
          paymentMethod: selectedPaymentMethod,
        }
      );

      await getPaymentMethods(filter);
      
    } catch (error) {
      console.error("Error setting default payment method:", error);
    }
    finally{
      setAnchorEl(null);
      setIsSettingAsDefault(false);
      setConfirmationModal(false);
    }
  }
  
  return (
    <>
      <ul className={`${styles["payment-methods"]} grid grid-cols-3 gap-4`}>
        {paymentMethods?.map((paymentMethod, index) => (
          <li
            key={paymentMethod._id}
            className={`relative flex justify-between ${
              paymentMethod?.is_default ? styles["default"] : ""
            } ${open && paymentMethod?._id === selectedPaymentMethod?._id ? styles["edit"] : ""}`}
          >
            <span>{paymentMethod?.title}</span>
            <span>**** **** **** {paymentMethod.last4}</span>
            {paymentMethod?.is_default && <span>Varsayılan</span>}

            <>
              {!paymentMethod?.is_default && (
                <button
                  aria-describedby={id}
                  type="button"
                  onClick={(event )=>{handleClick(event)
                    selectPaymentMethod(paymentMethod);
                  }}
                >
                  <MoreVertIcon />
                </button>
              )}
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
                  borderRadius: "10px",
                  bgcolor: "white",
                }}
                placement="top"
              >
                <div className={`${styles["popper"]} flex flex-col p-2`}>
                  <button
                    onClick={() => {
                      selectPaymentMethod(paymentMethod);
                      setConfirmationModal(true);
                    }}
                    className="border-b-[1px] border-gray-200 p-1 min-w-[115px]"
                  >
                   {isSettingAsDefault ?  <CircularProgress size={20} sx={{color:globalColors.primary}}/> : "Varsayılan Yap"}
                  </button>
                  <button
                    className='p-1 text-red-400'
                    onClick={() => {
                      removePaymentMethod(paymentMethod);
                    }}
                  >
                    {isDeleting ? <CircularProgress size={20} sx={{color:"red"}}/> : "Sil"}
                  </button>
                </div>
              </Popper>
            </>
          </li>
        ))}
      </ul>

      <WModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
      >
        <WModalCard
          bgColor="white"
          borderRadius="10px"
          className="flex flex-col gap-3"
        >
          <h1>Varsayılan Kartı Değiştirme</h1>
          <div>
            <p>
              {selectedPaymentMethod?.title} **** **** ****
              {selectedPaymentMethod.last4} kartınızı varsayılan kartınız olarak
            </p>
            değiştirmek istediğinize emin misiniz?
          </div>
          <div className="flex gap-3">
            <WButton
              onClick={() => setConfirmationModal(false)}
              className="flex-1"
              type="secondary"
              bgHover="#00000042"
              borderRadius="5px"
            >
              İptal
            </WButton>
            <WButton className="flex-1" borderRadius="5px" onClick={setDefaultPaymentMethod}>
            {isSettingAsDefault ?  <CircularProgress size={20} color="inherit"/> : "Değiştir"}

              
            </WButton>
          </div>
        </WModalCard>
      </WModal>
    </>
  );
};

export default PaymentMethods;
