import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "./checkout-form.module.scss";

interface CheckoutFormProps {
    button?: string;
    isDefault?: boolean;
    action?: string;
}

const CheckoutForm = ({button = "Ekle", isDefault = false}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log("event", event);
    if (!stripe || !elements) {
      return;
    }

    elements.submit();

    const card = elements.getElement(PaymentElement);

    const { error: createPaymentMethodError, paymentMethod } =
      await stripe.createPaymentMethod({
        element: card!,
      });

      attachPaymentMethod(paymentMethod?.id!);


  };
  const attachPaymentMethod = async (paymentMethodId: string) => {
    try {
      const response = await axios.post(
        "https://video-portal-75dd5.hq.spicaengine.com/api/fn-execute/attachPaymentMethod",
        {
          paymentMethodId,
          authorization: localStorage.getItem("videoPortal"),
          isDefault,
        }
      );
      console.log("response", response);
    }catch(error){
      console.error("Error attaching payment method:", error);
    }
  }

  return (
    <form action="" onSubmit={handleSubmit} className="flex flex-col h-[100%] justify-between">
      <PaymentElement />
      <button className={styles["submit"]}> {button} </button>
    </form>
  );
};

export default CheckoutForm;
