import styles from "./logo.module.scss";
import logo from "../../assets/img/logo.png";
import { FC } from "react";

interface iLogoProps {
  width?: number;
  height?: number;
  onClick?: () => void;
}
const WLogo: FC<iLogoProps> = ({ width, height = 40, onClick }) => {
  return (
    <img
      src={logo}
      alt="Logo"
      className={styles["logo"]}
      width={width}
      style={{
         height: height
      }}
      onClick={onClick}
    />
  );
};

export default WLogo;
