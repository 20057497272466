import { RealtimeConnectionOne, initialize } from "@spica-devkit/bucket";
import {
  configurations,
  Configurations,
  Sessions,
  sessions,
  video_metadata,
  Video_Metadata,
} from "./spica/bucket/bucket";

class ISessionService {
  constructor() {
    this._initialize();
  }

  private _session: Sessions | null = null;
  private _configs: Configurations[] | null = null;
  private _realtimeConnection: RealtimeConnectionOne<Sessions> | null = null;
  private _realtimeConnectionSubscription: any | null = null;
  private _sessionVideos: Video_Metadata[] | null = null;
  private _isPatientRegistrationIdUpdated = false;
  private _isSessionEndSoonPopupShowed = false;

  private _initialize() {
    return initialize({
      apikey: "4vrax19lq2dzg57",
      publicUrl: "https://video-portal-75dd5.hq.spicaengine.com/api",
    });
  }

  public get session() {
    return this._session;
  }

  public set session(data: Sessions | null) {
    this._session = data;
  }

  public get configs() {
    return this._configs;
  }

  public set configs(data: Configurations[] | null) {
    this._configs = data;
  }

  public get isSessionEndSoonPopupShowed() {
    return this._isSessionEndSoonPopupShowed;
  }

  public set isSessionEndSoonPopupShowed(data: boolean) {
    this._isSessionEndSoonPopupShowed = data;
  }

  public get isPatientRegistrationIdUpdated() {
    return this._isPatientRegistrationIdUpdated;
  }

  public set isPatientRegistrationIdUpdated(data: boolean) {
    this._isPatientRegistrationIdUpdated = data;
  }

  public get realtimeConnectionSubscription(): any | null {
    return this._realtimeConnectionSubscription;
  }

  public set realtimeConnectionSubscription(data: any | null) {
    this._realtimeConnectionSubscription = data;
  }

  public get sessionVideos(): Video_Metadata[] | null {
    return this._sessionVideos;
  }

  public set sessionVideos(data: Video_Metadata[] | null) {
    this._sessionVideos = data;
  }

  public set realtimeConnection(data: RealtimeConnectionOne<Sessions>) {
    this._realtimeConnection = data;
  }

  public patch(patchFields: Sessions) {
    return this._realtimeConnection?.patch({ ...patchFields });
  }

  public connectToSessionRealtime(sessionId: string) {
    return sessions.realtime.get(sessionId);
  }

  public getSessionVideos(videoIds: string[]) {
    return video_metadata.getAll({
      queryParams: {
        filter: {
          _id: {
            $in: videoIds,
          },
        },
      },
    });
  }

  public async fetchConfigs() {
    this._initialize();
    const res = await configurations.getAll();
    return (this.configs = res);
  }
  public getSessionsInformations(userId: string, data:any = {}) {
    return sessions
      .resolveRelations(["patient", "videos"])
      .filter({ user: userId, ...data })
      .getAll();
  }
  public updateSessionInformation(sessionId: string, patchFields: {}) {
    return sessions.patch({ _id: sessionId, ...patchFields });
  }
  createSession(data: Sessions) {
    return sessions.insert(data);
  }
}

const sessionService = new ISessionService();
export default sessionService;
