import React, { useEffect, useState } from "react";
import WAccountCard from "../../account-card/account-card";
import styles from "./seans.module.scss";
import WInput from "../../input/input";
import { globalColors } from "../../../style/colors";
import WButton from "../../button/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProduct } from "../../../redux/payment/payment.slice";
import databaseService from "../../../services/database.service";
import { CircularProgress } from "@mui/material";

const Seans = () => {
  const [additionalSeansCount, setAdditionalSeansCount] = useState<
    number | undefined
  >(undefined);
  const [seans, setSeans] = useState<any>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);


  const handleInputChange = (event: any) => {

    const count = parseInt(event.target.value);
    console.log(count);
    
    setAdditionalSeansCount(count);
  };

  const oneTimePayment = async (product: any, count: number) => {
    localStorage.setItem(
      "selectedPlan",
      JSON.stringify({ ...product, count, price: product?.price * count }) || ""
    );
    dispatch(setProduct(product));
    navigate("/payment/seans");
  };

  const getSeansProduct = async () => {
    try {
      const seansProduct = await databaseService.getPlans({
        "product.product_type": "one_seans",
      });

      
      setSeans(seansProduct[0]);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSeansProduct();
  }, [])
  
  return (
    loading ? <div className='overlay'>
      <CircularProgress /> </div> : (    <WAccountCard>
      <div className={styles["additional-products"]}>
        <div className={styles["session-input-container"]}>

          <WInput
            type="number"
            onChange={handleInputChange}
            value={additionalSeansCount!}
            color={globalColors.primary}
            bgColor={globalColors.lightPrimaryBackground}
            placeholder="Seans Miktarı Giriniz."
            placeHolderColor={globalColors.primary}
          />
          <span>Seans / {seans?.price} TL</span>
        </div>

        <div className={styles["buy-session"]}>
          <p>Toplam : Seans x {additionalSeansCount! * seans?.price || 0} TL</p>
          <WButton
            borderRadius="5px"
            onClick={() => oneTimePayment(seans, additionalSeansCount!)}
          >
            Ek Paket Satın Al
          </WButton>
        </div>
      </div>
    </WAccountCard>)
  )
};

export default Seans;
