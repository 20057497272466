import React from "react";
import styles from "./editable-info-row.module.scss";
import WInput from "../input/input";

interface EditableInfoRowProps {
  fieldKey: string;
  displayer: string;
  value?: string;
  editable: boolean;
  formik: any;
  onClick?: (event?: any) => void;
}

const inputKey = {
  PHONE_NUMBER: "phone_number",
  EMAIL: "email",
};


const getInputType = (key: string) => {
  switch (key) {
    case inputKey.PHONE_NUMBER:
      return "tel";
    case inputKey.EMAIL:
      return "email";
    default:
      return "text";
  }
};

const getMaxLength = (key: string) => {
  switch (key) {
    case inputKey.PHONE_NUMBER:
      return 14;
    default:
      return undefined;
  }
};

const checkStatus = (key: string) => {
  return key === "email"? true : false;
}

const EditableInfoRow: React.FC<EditableInfoRowProps> = ({
  fieldKey,
  displayer,
  value,
  editable,
  formik,
}) => {
  return (
    <div className={styles["info-row"]}>
      {displayer && <span className={styles["row-key"]}>{displayer}</span>}
      {editable ? (
        <WInput
          disabled={checkStatus(fieldKey)}
          value={formik.values[fieldKey] || ""}
          type={getInputType(fieldKey)}
          maxLength={getMaxLength(fieldKey)}
          onChange={formik.handleChange(fieldKey)}
          color="black"
        />
      ) : (
        <span>{value || "-"}</span>
      )}
    </div>
  );
};

export default EditableInfoRow;
