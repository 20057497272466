import WLogo from "../../components/logo/logo";
import { WMenu } from "../../components/menu/menu";
import styles from "./nav-bar.module.scss";
import { FC, useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { WMenuItem } from "../../components/menu-item/menu-item";
import { useNavigate } from "react-router";
import { WIconButton } from "../../components/icon-button/icon-button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import authService from "../../services/auth.service";
import { setUser } from "../../redux/user/user.slice";
import WNotification from "../../components/notification/notification";
import NotificationsIcon from '@mui/icons-material/Notifications';

const WNavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);

  const notifications = useSelector(
    (state: RootState) => state.notification.notifications
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    authService.logout();
    dispatch(setUser({}));
    navigate("/login");
  };

  return (
    <div className={styles["nav-bar"]}>
      <WLogo onClick={() => navigate("/")} />
      {user._id && (
        <div className={styles["account"]}>
          <span>
            Merhaba, {user.name} {user.surname}
          </span>
          <div className={styles["profile-icon"]}>
            <WIconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              onClick={handleMenu}
              className={styles["icon-button"]}
            >
              <AccountCircle className={styles["account-circle"]} />
            </WIconButton>
          </div>

          <div className={styles["notifications-batch"]} onClick={()=> navigate("/notification")}>
          <WNotification count={notifications?.length} />
            <NotificationsIcon />
          </div>

          <WMenu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <WMenuItem onClick={() => {navigate("/profile"); handleClose();}}>Hesap</WMenuItem>
            <WMenuItem onClick={handleLogout}>Oturumu Kapat</WMenuItem>
          </WMenu>
        </div>
      )}
    </div>
  );
};

export default WNavBar;
