import { initialize } from "@spica-devkit/bucket";
import { stripe_subscriptions, subscription_ } from "./spica/bucket/bucket";
import authService from "./auth.service";
import { PUBLIC_URL } from "../utils/constants";

class SubscriptionService {
  private token: string;

  constructor() {
    this.token = authService.getToken();
    initialize({
      identity: this.token,
      publicUrl: PUBLIC_URL,
    });
  }

  getSubscriptionInformation(subscriptionId: string) {
    return stripe_subscriptions.resolveRelations(["price.product", "payment_method", "updated_price.product"]).get(subscriptionId);
  }
}

const subscriptionService = new SubscriptionService();
export default subscriptionService;
