export const PUBLIC_APIKEY = "4vrax19lq2dzg57";
export const PUBLIC_URL = "https://video-portal-75dd5.hq.spicaengine.com/api";

export const terms = {
    gizlilik: "gizlilik",
    kullanimKosullari: "kullanim_kosullari",
    mesafeliSatisSozlesmesi: "mesafeli_satis_sozlesmesi",
    caymaIptal: "cayma_iptal"
    
}
