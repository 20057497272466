import { FC } from "react";
import VideoCard from "../../components/video-card/video-card";
import styles from "./video-slider.module.scss";
import { Video_Metadata } from "../../services/spica/bucket/bucket";
import { useDispatch } from "react-redux";
import { setSessionVideo } from "../../redux/session-video/session-video";
import "react-multi-carousel/lib/styles.css";

import Carousel from "react-multi-carousel";

interface iVideoSliderProps {
  title: string;
  videos: any[];
  onVideoClick: (video: Video_Metadata) => any;
}


const WVideoSlider: FC<iVideoSliderProps> = ({
  title,
  videos,
  onVideoClick,
}) => {

  const dispatch = useDispatch();

  const handleSessionVideo = (video: Video_Metadata) => {
    localStorage.setItem("video", JSON.stringify(video));
    dispatch(setSessionVideo(video));
    onVideoClick(video);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <h1 className={styles["title"]}>{title}</h1>

      <div className={styles["slider-container"]}>
        <Carousel
          responsive={responsive}
          showDots={true}
          containerClass={styles["carousel-container"]}
          itemClass={styles["slide-item"]}
        >
          {videos.map((item, i) => (
            <VideoCard
              key={i}
              title={item.subtitle}
              backgroundImage={
                item?.thumbnail || "https://picsum.photos/200/300"
              }
              duration={item.duration_seconds}
              onClick={() => handleSessionVideo(item)}
            />
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default WVideoSlider;
