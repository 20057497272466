import React, { FC } from "react";
import WModalCard from "../../components/modal-card/modal-card";
import { Modal } from "@mui/material";
import styles from "./add-patient.module.scss";
import WCardTitle from "../../components/card-title/card-title";
import WInput from "../../components/input/input";
import WButton from "../../components/button/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import patientService from "../../services/patient.service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface iAddPatientProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const AddPatient: FC<iAddPatientProps> = ({ open, setOpen }) => {
  const user = useSelector((state: RootState) => state.user.user);

  const formik = useFormik({
    initialValues: {
      name_surname: "",
      email: "",
      phone_number: "",
      doctor: user?._id,
    },
    onSubmit: async (values) => {
      try {
        await patientService.insert(values);
        setOpen(false);
      } catch (error) {
        console.error(error);
        formik.resetForm();
        setOpen(false);
      }
    },
    validationSchema: Yup.object().shape({
      name_surname: Yup.string().required("Please enter name and surname"),
      email: Yup.string().required("Please enter email"),
      phone_number: Yup.string().required("Please enter phone_number"),
    }),
  });

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <WModalCard
        width="40%"
        borderRadius="10px"
        className={styles["card"]}
        padding="2rem 1.5rem"
        type="black"
      >
        <div className={styles["content"]}>
          <WCardTitle fontSize="1.2rem">Danışan Ekle</WCardTitle>

          <WInput
            value={formik.values.name_surname}
            onChange={formik.handleChange("name_surname")}
            placeholder="Danışan Ad Soyad"
            className={styles["input"]}
          />
          {formik.touched.name_surname && formik.errors.name_surname ? (
            <span className={styles["error"]}>
              {formik.errors.name_surname}
            </span>
          ) : null}

          <WInput
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            placeholder="E-posta"
            className={styles["input"]}
          />
          {formik.touched.email && formik.errors.email ? (
            <span className={styles["error"]}>{formik.errors.email}</span>
          ) : null}

          <WInput
            value={formik.values.phone_number}
            onChange={formik.handleChange("phone_number")}
            placeholder="Telefon Numarası"
            className={styles["input"]}
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <span className={styles["error"]}>
              {formik.errors.phone_number}
            </span>
          ) : null}

          <WButton
            onClick={formik.handleSubmit}
            className={styles["button"]}
            bgHover="rgb(249 76 16 / 74%)"
            borderRadius="10px"
            horizontalSize="100%"

          >
            Kaydet
          </WButton>
        </div>
      </WModalCard>
    </Modal>
  );
};

export default AddPatient;
