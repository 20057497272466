import * as Identity from "@spica-devkit/identity";
import { PUBLIC_APIKEY, PUBLIC_URL } from "../utils/constants";
import { completeForgotPassword, forgotPassword, register, removeAccount } from "./spica/functions/Login_&_Register";
import { log_in_session } from "./spica/bucket/bucket";
import axios from "axios";

class AuthService {
  tokenName = "videoPortal";

 async isLoggedIn() {
  try {
    Identity.initialize({
      apikey: "",
      publicUrl: "https://video-portal-75dd5.hq.spicaengine.com/api",
    });
    const token = this.getToken();
    
     return Identity.verifyToken(token);
  } catch (error) {
    
  }
    
  }

  async login(username: string, password: string) {
    this.identityInitialize();
    return Identity.login(username, password);
  }

  setToken(token: string) {
    localStorage.setItem(this.tokenName, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenName) as string;
  }

  logout() {
    return localStorage.removeItem(this.tokenName);
  }

  verifyToken(token: string) {
    return Identity.verifyToken(token);
  }

  identityInitialize() {
    return Identity.initialize({
      apikey: "4vrax19lq2dzg57",
      publicUrl: PUBLIC_URL,
    });
  }

  registerUser(data: {}) {
    return register({
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `APIKEY ${PUBLIC_APIKEY}`,
      },
    });
  }

  removeUserAccount(data: {}): Promise<any> {
    return removeAccount({
      data,
      Headers: {
        "Content-Type": "application/json",
        Authorization: `APIKEY ${PUBLIC_APIKEY}`,
      }
    });
  }

  handleForgetPassword(data: {}): Promise<any> {
    return forgotPassword({
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `APIKEY ${PUBLIC_APIKEY}`,
      },
    });
  }

  handleCompletePassword(data: {}): Promise<any> {
    return completeForgotPassword({
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `APIKEY ${PUBLIC_APIKEY}`,
      },
    });

  }
  async changePasswordWhileLoggedIn(userId: string, password: string) {
    Identity.initialize({
      identity: localStorage.getItem("videoPortal")!,
      publicUrl: PUBLIC_URL,
    })

    const decoded: any = await this.verifyToken(localStorage.getItem("videoPortal")!);
    const identity = await Identity.get(decoded?._id);

    const updatedIdentity = {
      identifier: identity?.identifier,
      password,
      attributes: identity?.attributes,
      policies: identity?.policies,
    }

    if (!decoded?._id) {
      return;
    }
    await Identity.update(decoded?._id, updatedIdentity);
    return this.attachPolicy(decoded?._id, identity?.policies!);
  }

  attachPolicy(identityId: string, policyIds: string[]) {
    Identity.initialize({
      apikey: "1edu18lxujvgyp",
      publicUrl: PUBLIC_URL,
    })
    return Identity.policy.attach(identityId, policyIds);
  }

  // realtimeLoginSession() {
  //   return log_in_session.realtime.getAll();
  // }

  async refreshToken() {
    try {
      const response = await axios.post('/api/refresh-token', {
        refreshToken: this.getRefreshToken()
      });
      this.setToken(response.data.token);
      return response.data.token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      this.logout();
      throw error;
    }
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
}

const authService = new AuthService();
export default authService;
