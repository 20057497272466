import { CSSProperties, FC, ReactNode } from "react";
import { globalColors } from "../../style/colors";
import styles from "./modal-card.module.scss";

export interface iModalCard {
  bgColor?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
  border?: string;
  width?: string;
  height?: string;
  position?: any;
  className?: string;
  children?: ReactNode;

  type?: "primary" | "secondary" | "black" | "white";
}

const WModalCard: FC<iModalCard> = ({
  // borderRadius,
  border,
  margin,
  width,
  height,
  className,
  children,
  position = "relative",
  type = "primary",
}) => {
  const modalCardStyles: CSSProperties = {
    // borderRadius,
    border,
    margin,
    width,
    height,
    position,
  };

  return (
    <div style={modalCardStyles} className={`${styles["modal-card"]} ${styles[`${type}`]}  ${className}`}>
      {children}
    </div>
  );
};

export default WModalCard;
