import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iNotification } from "../../interfaces/common";
import { NotificationProps } from "../../services/interfaces";

interface iNotificationState {
    notifications: NotificationProps[];
    notificationsMetaData: any;
}

const initialState: iNotificationState = {
    notifications: [],
    notificationsMetaData: {},
};


export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications(state, action: PayloadAction<any[]>) {
            state.notifications = action.payload;
        },
        setNotificationMetaData(state, action: PayloadAction<any>) {
            state.notificationsMetaData = action.payload;
        }
    },
});

export const { setNotifications, setNotificationMetaData } = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer;