import { Notification_Metadata, User_Specific_Notification, notification_metadata, notifications, user_specific_notification } from "./spica/bucket/bucket";

class NotificationService {

    insertNotificationMetaData = async (notificationMetaData: Notification_Metadata) => {
        return notification_metadata.insert(notificationMetaData);
    }

    getNotifications(filter: {} = {}) {
        return notifications.getAll({ queryParams: { filter: filter, sort: { created_at: -1 }, } });
    }
    getUserSpecificNotifications(filter: {} = {}) {
        return user_specific_notification.getAll({ queryParams: { filter: filter, sort: { created_at: -1 }, } });
    }
    getNotificationsMetaData(userId: string) {
        return notification_metadata.getAll({ queryParams: { filter: { user: userId } } });
    }
    updateNotificationMetaData(id: string, notificationMetaData: Notification_Metadata) {
        return notification_metadata.patch({ _id: id, ...notificationMetaData });
    }
    uppdateUserSpecificNotification(id: string, notification: User_Specific_Notification) {
        return user_specific_notification.patch({ _id: id, ...notification });
    }
}

const notificationService = new NotificationService();
export default notificationService;