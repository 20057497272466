import { useNavigate } from "react-router-dom";
import styles from "./side-bar.module.scss";
import { FC } from "react";
import { iPage } from "../../interfaces/common";
import WNotification from "../../components/notification/notification";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import authService from "../../services/auth.service";

interface iSideBar {
  pages: iPage[];
  pathOnChanged: (path: string) => void;
}

const WSideBar: FC<iSideBar> = ({ pages, pathOnChanged }) => {
  const navigate = useNavigate();
  const currenctPath = window.location.pathname;

  const user = useSelector((state: RootState) => state.user.user);

  const handleLinkOnClick = (path: string, title: string) => {

    if (path === "remove-account") {
      try {
        authService.removeUserAccount({ email: user?.email });
      } catch (error) {
        console.error(error);
      }
    }

    navigate(`/${path}`);
    pathOnChanged(title);
  };

  const notifications = useSelector(
    (state: RootState) => state.notification.notifications
  );

  const renderLinks = () => {
    return pages.map((el) => (
      <div
        onClick={() => handleLinkOnClick(el.path, el.title)}
        key={el.path}
        className={`${styles["link"]} ${
          currenctPath.endsWith(el.path) && styles["active"]
        }`}
      >
        <span className={`${styles["title"]} ${(el.path === "remove-account" ? "danger" : "")}`}>
          {el.title}
          {el.path === "notification" && (
            <WNotification
              count={notifications?.length}
              customStyle={styles["notification"]}
            />
          )}
        </span>
      </div>
    ));
  };

  return (
    <div className={styles["side-bar"]}>
      <div className={styles["content"]}>{renderLinks()}</div>
    </div>
  );
};

export default WSideBar;
