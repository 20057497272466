import styles from "./account-card.module.scss";
import { FC, ReactNode } from "react";

interface iAccountCard {
  children: ReactNode;
  className?: string;
}
const WAccountCard: FC<iAccountCard> = ({ children, className }) => {
  return (
    <div className={`${styles["account-card"]} ${className}`}>{children}</div>
  );
};

export default WAccountCard;
