import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Video_Metadata } from "../../services/spica/bucket/bucket";

interface iSessionVideoState {
  sessionVideo: Video_Metadata;
}

const initialState: iSessionVideoState = {
  sessionVideo: {},
};

export const sessionVideoSlice = createSlice({
  name: "sessionVideo",
  initialState,
  reducers: {
    setSessionVideo(state, action: PayloadAction<any>) {
      state.sessionVideo = action.payload;
    },
  },
});

export const { setSessionVideo } = sessionVideoSlice.actions;
export const sessionVideoReducer = sessionVideoSlice.reducer;
