import React, { FC, ReactNode } from "react";
import MuiButton from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";

interface iTextButtonProps {
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  padding?: string;
  margin?: string;
  underline?: boolean;
  children: ReactNode;
  fontSize?: string;
  textTransform?: string;
  onClick?: () => void;
}

const WTextButton: FC<iTextButtonProps> = ({
  disabled,
  children,
  color = "black",
  hoverColor = "blue",
  padding = "0",
  margin = "0",
  underline,
  fontSize = "1rem",
  textTransform = "capitalize",
  onClick,
}) => {
  const globalStyles: SxProps<Theme> = {
    color,
    padding,
    margin,
    "&:hover": {
      background: "none",
      textDecoration: underline ? "underline" : "none",
      color: hoverColor,
    },
    textDecoration: underline ? "underline" : "none",
    fontSize,
    textTransform,
  };

  return (
    <MuiButton disabled={disabled} onClick={onClick} sx={globalStyles}>
      {children}
    </MuiButton>
  );
};

export default WTextButton;
