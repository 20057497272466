import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Button,
  ButtonProps,
  CircularProgress,
  Box,
  Container,
  ContainerProps,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  // Modal,
  ModalProps,
  Stack,
  CircularProgressProps,
  StackProps,
  TextField,
  TextFieldProps,
  ListProps,
  ListItemProps,
  ListItemTextProps,
  ListItemText,
  TextareaAutosize,
  TextareaAutosizeProps,
  Tabs,
  TabsProps,
  Tab,
  TabProps,
  SliderProps,
  Slider,
  FormControlLabelProps,
  FormControlLabel,
  CheckboxProps,
  Checkbox,
  Select,
  SelectProps,
  MenuItem,
  MenuItemProps,
  Radio,
  RadioProps,
  InputLabel,
  InputLabelProps,
  FormControl,
  FormControlProps,
  DialogProps,
  Dialog,
  DialogContent,
  DialogContentProps,
  BoxProps,
  Tooltip,
  TooltipProps,
  SkeletonProps,
  Skeleton,
  Chip,
  ChipProps,
  ToggleButton,
  ToggleButtonProps,
  ToggleButtonGroupProps,
  ToggleButtonGroup,
  TypographyProps,
  Typography,
  GridProps,
  Grid,
  InputProps,
  Input,
} from "@mui/material";

import Modal from "@mui/material/Modal";



import { TabContext, TabContextProps, TabPanel, TabPanelProps } from "@mui/lab";
import { LoadingButton } from "@mui/lab";
import { LoadingButtonProps } from "@mui/lab/LoadingButton";
import React from "react";

type IContainer = {} & ContainerProps;
export const WContainer = (props: IContainer) => {
  return <Container {...props}> {props.children}</Container>;
};

type IMuiList = {} & ListProps;
export const WList = (props: IMuiList) => {
  return (
    <List
      {...props}
    >
      {props.children}
    </List>
  );
};
type IMuiListItemButton = {} & ListItemButtonProps;
export const WListItemButton = (props: IMuiListItemButton) => {
  return <ListItemButton {...props}>{props.children} </ListItemButton>;
};

type IMuiListItem = {} & ListItemProps;
export const WListItem = (props: IMuiListItem) => {
  return <ListItem {...props}>{props.children} </ListItem>;
};

type IMuiListItemIcon = {} & ListItemIconProps;
export const WListItemIcon = (props: IMuiListItemIcon) => {
  return <ListItemIcon {...props}>{props.children} </ListItemIcon>;
};

type IMuiListItemText = {} & ListItemTextProps;
export const WListItemText = (props: IMuiListItemText) => {
  return <ListItemText {...props}>{props.children} </ListItemText>;
};

type IMuiTextField = {} & TextFieldProps;
export const WTextField = (props: IMuiTextField) => {
  return (
    <TextField {...props}>{props.children}</TextField>
  );
};

type IMuiButton = {} & ButtonProps;
export const WButton = (props: IMuiButton) => {
  return (
    <Button
      {...props}
    >
      {props.children}
    </Button>
  );
};

type IMuiLoadingButton = {} & LoadingButtonProps;
export const WLoadingButton = (props: IMuiLoadingButton) => {
  return (
    <LoadingButton
      {...props}
    >
      {props.children}
    </LoadingButton>
  );
};

type IMuiModal =  ModalProps;
// export const WModal = (props: IMuiModal) => {
//   return (
//     <Modal {...props}>
//       {props.children}
//     </Modal>
//   );
// };
export const WModal = React.forwardRef<HTMLDivElement, IMuiModal>((props, ref) => {
  return <Modal ref={ref} {...props} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >{props.children}</Modal>;
});

type IIconButton = {} & IconButtonProps;
export const WIconButton = (props: IIconButton) => {
  return <IconButton {...props}>{props.children}</IconButton>;
};

type IAccordion = {} & AccordionProps;
export const WAccordion = (props: IAccordion) => {
  return <Accordion {...props}>{props.children}</Accordion>;
};

type IAccordionSummary = {} & AccordionSummaryProps;
export const WAccordionSummary = (props: IAccordionSummary) => {
  return (
    <AccordionSummary {...props}>
      {props.children}
    </AccordionSummary>
  );
};

type IAccordionDetails = {} & AccordionDetailsProps;
export const WAccordionDetails = (props: IAccordionDetails) => {
  return <AccordionDetails {...props}>{props.children}</AccordionDetails>;
};

type IMuiStack = {} & StackProps;
export const WStack = (props: IMuiStack) => {
  return <Stack {...props}>{props.children} </Stack>;
};

type ITextareaAutosize = {
  maxRows?: number;
} & TextareaAutosizeProps;
export const WTextareaAutosize = (props: ITextareaAutosize) => {
  return <TextareaAutosize maxRows={props.maxRows} {...props} />;
};

type ICircularProgress = {} & CircularProgressProps;
export const WCircularProgress = (props: ICircularProgress) => {
  return <CircularProgress {...props} />;
};

type ITabContext = {} & TabContextProps;
export const WTabContext = (props: ITabContext) => {
  return <TabContext {...props} />;
};

type ITabs = {} & TabsProps;
export const WTabs = (props: ITabs) => {
  return (
    <Tabs
      {...props}
    />
  );
};

type ITab = {} & TabProps;
export const WTab = (props: ITab) => {
  return <Tab {...props} />;
};

type ITabPanel = {} & TabPanelProps;
export const WTabPanel = (props: ITabPanel) => {
  return <TabPanel {...props} />;
};

type ISlider = {} & SliderProps;
export const WSlider = (props: ISlider) => {
  return <Slider {...props} />;
};

type ICheckbox = {} & CheckboxProps;
export const WCheckbox = (props: ICheckbox) => {
  return <Checkbox {...props} />;
};

type IFormControlLabel = {} & FormControlLabelProps;
export const WFormControlLabel = (props: IFormControlLabel) => {
  return <FormControlLabel {...props} />;
};

type ISelect = {} & SelectProps;
export const WSelect = (props: ISelect) => {
  return (
    <Select
      {...props}
    >{props.children}</Select>
  );
};

type ISegment = {} & ToggleButtonProps;
export const WSegment = (props: ISegment) => {
  return <ToggleButton {...props} />;
};

type ISegmentGroup = {} & ToggleButtonGroupProps;
export const WSegmentGroup = (props: ISegmentGroup) => {
  return <ToggleButtonGroup {...props} />;
};

type IMenuItem = {} & MenuItemProps;
export const WMenuItem = (props: IMenuItem) => {
  return <MenuItem {...props} />;
};

type IRadio = {} & RadioProps;
export const WRadio = (props: IRadio) => {
  return <Radio {...props} />;
};

type IInputLabel = {} & InputLabelProps;
export const WInputLabel = (props: IInputLabel) => {
  return <InputLabel {...props} />;
};

type IFormControl = {} & FormControlProps;
export const WFormControl = (props: IFormControl) => {
  return <FormControl {...props} />;
};
type IDialog = {} & DialogProps;
export const WDialog = (props: IDialog) => {
  return <Dialog {...props}>{props.children}</Dialog>;
};

type IDialogContent = {} & DialogContentProps;
export const WDialogContent = (props: IDialogContent) => {
  return <DialogContent {...props}>{props.children}</DialogContent>;
};

type IBox = {} & BoxProps;
export const WBox = (props: IBox) => {
  return (
    <Box
      {...props}
    >
      {props.children}
    </Box>
  );
};

type ITypography = {} & TypographyProps;
export const WTypography = (props: ITypography) => {
  return <Typography {...props} />;
};

type IGrid = {} & GridProps;
export const WGrid = (props: IGrid) => {
  return <Grid {...props} />
}

type IInput = {} & InputProps;
export const WInput = (props: IInput) => {
  return <Input {...props} />
};


type ITooltip = {} & TooltipProps;
export const WTooltip = (props: ITooltip) => {
  return <Tooltip {...props} />;
};

type ISkeleton = {} & SkeletonProps;
export const WSkeleton = (props: ISkeleton) => {
  return <Skeleton {...props} />;
};

type IChip = {} & ChipProps;
export const WChip = (props: IChip) => {
  return <Chip {...props} />;
};

